/**
 * Navigation styling partial
 */


ul.menu {
  @include reset-list();
}



.main-menu {
  float: left;
  width: 100%;
  display: none;


  @media (min-width: $break-3) {
    display: block;
    float: right;
    width: 60%;
    padding-top: 0.5em;

    .title {
      display: none;
    }

    .menu {
      display: table;
      width: auto;
      table-layout: fixed;
      text-align: right;
      border-collapse:separate;
      float: right;
    }

    .menu__item {
      float: left;

      line-height: 2em;
      display: table-cell;
      position: relative;
      // margin: 0 10px;

      &.last {
        margin-right: 0;
      }

      .menu__link {
        color: $text-grey;
      }


      &.active,
      &.active-trail,
      &is-active-trail {
        background-color: transparent;
        font-weight: 600;

        a {
          color: $red
        }

      }
      &:hover {
        background-color: transparent;
        a {
          color: $red
        }
      }

      .frontpage-header & {

        &.active,
        &.active-trail,
        &is-active-trail {
          background-color: #8b8b8b;
        }
        &:hover {
          background-color: #8b8b8b;
        }

        .menu__link {
          color: $white;
        }

      }

      .menu__link {
        padding: 0 8px;

        display: block;

      }
    }
  }

  @media (min-width: $break-3) {
    .menu__item {
      margin: 0 5px;
    }
  }
}

.language-nav {
  float: left;
  width: 100%;
  background-color: $grey;
  margin-right: -200em;
  margin-left: -200em;
  padding-right: 200em;
  padding-left: 200em;
  box-sizing: content-box;
  margin-top: 2em;
  margin-bottom: 2em;
  padding-top: 1.3em;
  padding-bottom: 1.3em;

  @media (min-width: $break-3) {
    display: none;
  }

}

.mobile-menu-button {
  float: left;
  cursor: pointer;
  text-transform: uppercase;
  padding-left: 2em;
  position: relative;

  &:before {
    @include icomoon();
    content: "\e901";
    position: absolute;
    left: 3px;
    top: 3px;
  }
}

.mobile-menu {
  visibility: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999;
  top: 0;
  left: -100%;
  -webkit-transition: left 0.3s ease;
  transition: left 0.3s ease;
  background-color: $red;
  padding: 1.5em;

  .title {
    color: $white;
    text-transform: uppercase;
    font-size: 16px;
    letter-spacing: 3px;
    position: relative;
    cursor: pointer;

    &:after {
      content: "\e903";
      @include icomoon();
      position: absolute;
      right: 0px;
      top: 12px;
    }
  }

  .menu__link {
    color: $white;
    line-height: 3em;
    display: block;
    padding-left: 1em;
    @include transition(background-color 0.2s ease);

    &:hover,
    &.active {
      background-color: #dd5c6b;
    }
  }


  &.open {
    visibility: visible;
    left: 0;
  }
}

.desktop-language-switcher {
  display: none;

  &.front {
    a {
      color: $white;

      &:hover,
      &.active {
        color: $white;
      }
    }
  }

  @media (min-width: $break-3) {
    display: block;

    .language-switcher {
      width: 100%;
      margin-bottom: 1em;
    }

  }

}

//language switcher

.language-switcher {
  float: right;
  margin-bottom: 0;

  text-align: right;
  > ul {
    padding: 0;
    margin: 0;
    list-style: none;


    > li {

      margin-left: 0.5em;
      list-style-type: none;
      list-style-image: none;
      display: inline-block;

      a {
        padding: 0.4em 0;
        text-decoration: none;
        color: $text-grey;

        &:hover,
        &.active {
          font-weight: 700;
          color: $black;
        }
      }
    }
  }
}


//footer menu
.footer-menu {
  width: 100%;
  text-align: center;
  margin: 2em 0 3em;

  a {
    color: $text-grey;
    @include transition(all 0.3s ease);

    &:hover {
      text-decoration: underline;
      color: $black;
    }
  }

  @media (min-width: $break-1) {
    width: 50%;
    float: left;
    text-align: left;
    padding-left: 2em;
    margin: 0 0 2em 0;
  }

  @media (min-width: $break-2) {
    width: 25%;
    padding-left: 4em;
    margin: 0;
  }
}

.bottom-menu {

  text-align: center;
  color: $text-grey;
  margin-bottom: 1em;


  ul.menu {
    display: inline-block;
    margin: 0 auto;

    li {
      float: left;
      padding-right: 1em;

      a {
        color: $text-grey;
      }
    }
  }
}

//breadcrumb

#breadcrumb {
  margin: 1.5em 0;

  ul {
    display: inline-block;
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
      float: left;
      padding-right: 30px;
      position: relative;

      &:after {
        position: absolute;
        right: 11px;
        content: '\203A';
      }

      a {
        color: $red;
      }

      &:last-child {
        &:after {
          content: none;
        }
      }
    }
  }
}
