article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
progress,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

progress {
  vertical-align: baseline;
}

template,
[hidden] {
  display: none;
}

a {
  color: #0072b9;
  -webkit-text-decoration-skip: objects;
}

:visited {
  color: #003353;
}

a:active {
  color: #c00;
}

a:active:not(.button) {
  background-color: transparent;
}

a:active,
a:hover {
  outline-width: 0;
}

@media print {
  :link,
  :visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
    font-weight: normal;
    font-size: 16px;
    text-decoration: none;
  }
  a[href^='javascript:']:after,
  a[href^='#']:after {
    content: '';
  }
}

h1,
.header__site-name {
  font-size: 2rem;
  line-height: 3rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

h2 {
  font-size: 1.5rem;
  line-height: 2.25rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

h3 {
  font-size: 1.25rem;
  line-height: 2.25rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

h4 {
  font-size: 1rem;
  line-height: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

h5 {
  font-size: 0.875rem;
  line-height: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

h6 {
  font-size: 0.625rem;
  line-height: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

abbr[title] {
  border-bottom: 0;
  text-decoration: underline;
  text-decoration: underline dotted;
}

@media print {
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
}

b,
strong {
  font-weight: inherit;
}

strong,
b {
  font-weight: bolder;
}

pre,
code,
kbd,
samp,
var {
  font-family: Menlo, "DejaVu Sans Mono", "Ubuntu Mono", Courier, "Courier New", monospace, sans-serif;
  font-size: 1em;
}

dfn {
  font-style: italic;
}

mark {
  background-color: #fd0;
  color: #000;
}

small {
  font-size: 0.875rem;
}

sub,
sup {
  font-size: 0.625rem;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

.divider,
hr {
  margin: 1.5rem 0;
  border: 0;
  border-top: 1px solid #cccccc;
}

.divider > :first-child, hr > :first-child {
  margin-top: 1.5rem;
}

blockquote {
  margin: 1.5rem 2rem;
}

dl,
menu,
ol,
ul {
  margin: 1.5rem 0;
}

ol ol,
ol ul,
ul ol,
ul ul {
  margin: 0;
}

dd {
  margin: 0 0 0 32px;
}

[dir="rtl"] dd {
  margin: 0 32px 0 0;
}

menu,
ol,
ul {
  padding: 0 0 0 32px;
}

[dir="rtl"] menu, [dir="rtl"]
ol, [dir="rtl"]
ul {
  padding: 0 32px 0 0;
}

figure {
  margin: 1.5rem 0;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

p,
pre {
  margin: 1.5rem 0;
}

img {
  border-style: none;
}

img,
svg {
  max-width: 100%;
  height: auto;
}

svg:not(:root) {
  overflow: hidden;
}

button,
input,
optgroup,
select,
textarea {
  box-sizing: border-box;
  max-width: 100%;
  font: inherit;
  margin: 0;
}

button {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

input {
  overflow: visible;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type='search']::-webkit-search-cancel-button, [type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-input-placeholder {
  color: inherit;
  opacity: .54;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

.button,
button,
html [type='button'],
[type='reset'],
[type='submit'] {
  -moz-appearance: button;
  -webkit-appearance: button;
}

.button,
button,
[type='button'],
[type='reset'],
[type='submit'] {
  display: inline-block;
  padding: 2px 6px;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  color: #000;
}

.button::-moz-focus-inner,
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

.button:-moz-focusring,
button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText;
}

.button:hover, .button:focus, .button:active,
button:hover,
button:focus,
button:active,
[type='button']:hover,
[type='button']:focus,
[type='button']:active,
[type='reset']:hover,
[type='reset']:focus,
[type='reset']:active,
[type='submit']:hover,
[type='submit']:focus,
[type='submit']:active {
  text-decoration: none;
  color: #000;
}

[disabled].button,
button[disabled],
[disabled][type='button'],
[disabled][type='reset'],
[disabled][type='submit'] {
  cursor: default;
  color: #999999;
}

[disabled].button:hover,
button[disabled]:hover,
[disabled][type='button']:hover,
[disabled][type='reset']:hover,
[disabled][type='submit']:hover, [disabled].button:focus,
button[disabled]:focus,
[disabled][type='button']:focus,
[disabled][type='reset']:focus,
[disabled][type='submit']:focus, [disabled].button:active,
button[disabled]:active,
[disabled][type='button']:active,
[disabled][type='reset']:active,
[disabled][type='submit']:active {
  color: #999999;
}

[type='checkbox'],
[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}

fieldset {
  padding: 0.525rem 0.9375rem 0.975rem;
  border: 1px solid #cccccc;
  margin: 0 2px;
}

legend {
  box-sizing: border-box;
  display: table;
  max-width: 100%;
  white-space: normal;
  color: inherit;
  margin-left: -5px;
  padding: 0 5px;
}

label {
  display: block;
  font-weight: bold;
}

optgroup {
  font-weight: bold;
}

textarea {
  overflow: auto;
}

table {
  margin: 1.5rem 0;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

td,
th {
  padding: 0;
}

.layout-3col {
  margin-left: -20px;
  margin-right: -20px;
  padding-left: 0;
  padding-right: 0;
}

.layout-3col:before {
  content: "";
  display: table;
}

.layout-3col:after {
  content: "";
  display: table;
  clear: both;
}

.layout-3col__full, .layout-3col__left-content, .layout-3col__right-content, .layout-3col__left-sidebar, .layout-3col__right-sidebar, .layout-3col__first-left-sidebar, .layout-3col__second-left-sidebar, .layout-3col__col-1, .layout-3col__col-2, .layout-3col__col-3, .layout-3col__col-4, .layout-3col__col-x {
  clear: both;
  padding-left: 20px;
  padding-right: 20px;
  float: left;
  width: 100%;
  margin-left: 0%;
  margin-right: -100%;
}

[dir="rtl"] .layout-3col__full, [dir="rtl"] .layout-3col__left-content, [dir="rtl"] .layout-3col__right-content, [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__right-sidebar, [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__second-left-sidebar, [dir="rtl"] .layout-3col__col-1, [dir="rtl"] .layout-3col__col-2, [dir="rtl"] .layout-3col__col-3, [dir="rtl"] .layout-3col__col-4, [dir="rtl"] .layout-3col__col-x {
  float: right;
  margin-right: 0%;
  margin-left: -100%;
}

@media (min-width: 777px) {
  .layout-3col {
    margin-left: -12px;
    margin-right: -13px;
    padding-left: 0;
    padding-right: 0;
  }
  .layout-3col:before {
    content: "";
    display: table;
  }
  .layout-3col:after {
    content: "";
    display: table;
    clear: both;
  }
  [dir="rtl"] .layout-3col {
    margin-left: -13px;
    margin-right: -12px;
  }
  .layout-3col__full, .layout-3col__left-content, .layout-3col__right-content, .layout-3col__left-sidebar, .layout-3col__right-sidebar {
    float: left;
    width: 100%;
    margin-left: 0%;
    margin-right: -100%;
    padding-left: 12px;
    padding-right: 13px;
  }
  [dir="rtl"] .layout-3col__full, [dir="rtl"] .layout-3col__left-content, [dir="rtl"] .layout-3col__right-content, [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__right-sidebar {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  [dir="rtl"] .layout-3col__full, [dir="rtl"] .layout-3col__left-content, [dir="rtl"] .layout-3col__right-content, [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__right-sidebar {
    padding-left: 13px;
    padding-right: 12px;
  }
  .layout-3col__first-left-sidebar, .layout-3col__col-1, .layout-3col__col-3, .layout-3col__col-x:nth-child(2n + 1) {
    float: left;
    width: 50%;
    margin-left: 0%;
    margin-right: -100%;
    padding-left: 12px;
    padding-right: 13px;
  }
  [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__col-1, [dir="rtl"] .layout-3col__col-3, [dir="rtl"] .layout-3col__col-x:nth-child(2n + 1) {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__col-1, [dir="rtl"] .layout-3col__col-3, [dir="rtl"] .layout-3col__col-x:nth-child(2n + 1) {
    padding-left: 13px;
    padding-right: 12px;
  }
  .layout-3col__second-left-sidebar, .layout-3col__col-2, .layout-3col__col-4, .layout-3col__col-x:nth-child(2n) {
    clear: none;
    float: left;
    width: 50%;
    margin-left: 50%;
    margin-right: -100%;
    padding-left: 12px;
    padding-right: 13px;
  }
  [dir="rtl"] .layout-3col__second-left-sidebar, [dir="rtl"] .layout-3col__col-2, [dir="rtl"] .layout-3col__col-4, [dir="rtl"] .layout-3col__col-x:nth-child(2n) {
    float: right;
    margin-right: 50%;
    margin-left: -100%;
  }
  [dir="rtl"] .layout-3col__second-left-sidebar, [dir="rtl"] .layout-3col__col-2, [dir="rtl"] .layout-3col__col-4, [dir="rtl"] .layout-3col__col-x:nth-child(2n) {
    padding-left: 13px;
    padding-right: 12px;
  }
}

@media (min-width: 999px) {
  .layout-3col__full {
    float: left;
    width: 100%;
    margin-left: 0%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__full {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  .layout-3col__left-content {
    float: left;
    width: 66.66667%;
    margin-left: 0%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__left-content {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  .layout-3col__right-content {
    float: left;
    width: 66.66667%;
    margin-left: 33.33333%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__right-content {
    float: right;
    margin-right: 33.33333%;
    margin-left: -100%;
  }
  .layout-3col__left-sidebar, .layout-3col__first-left-sidebar, .layout-3col__second-left-sidebar {
    clear: right;
    float: right;
    width: 33.33333%;
    margin-right: 66.66667%;
    margin-left: -100%;
  }
  [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__second-left-sidebar {
    clear: left;
  }
  [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__second-left-sidebar {
    float: left;
    margin-left: 66.66667%;
    margin-right: -100%;
  }
  .layout-3col__right-sidebar {
    clear: right;
    float: right;
    width: 33.33333%;
    margin-right: 0%;
    margin-left: -100%;
  }
  [dir="rtl"] .layout-3col__right-sidebar {
    clear: left;
  }
  [dir="rtl"] .layout-3col__right-sidebar {
    float: left;
    margin-left: 0%;
    margin-right: -100%;
  }
  .layout-3col__col-1, .layout-3col__col-x:nth-child(3n+1) {
    clear: both;
    float: left;
    width: 33.33333%;
    margin-left: 0%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__col-1, [dir="rtl"] .layout-3col__col-x:nth-child(3n+1) {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  .layout-3col__col-2, .layout-3col__col-x:nth-child(3n+2) {
    clear: none;
    float: left;
    width: 33.33333%;
    margin-left: 33.33333%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__col-2, [dir="rtl"] .layout-3col__col-x:nth-child(3n+2) {
    float: right;
    margin-right: 33.33333%;
    margin-left: -100%;
  }
  .layout-3col__col-3, .layout-3col__col-x:nth-child(3n) {
    clear: none;
    float: left;
    width: 33.33333%;
    margin-left: 66.66667%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__col-3, [dir="rtl"] .layout-3col__col-x:nth-child(3n) {
    float: right;
    margin-right: 66.66667%;
    margin-left: -100%;
  }
  .layout-3col__col-4 {
    display: none;
  }
}

.layout-3col__grid-item-container {
  padding-left: 0;
  padding-right: 0;
}

.layout-3col__grid-item-container:before {
  content: "";
  display: table;
}

.layout-3col__grid-item-container:after {
  content: "";
  display: table;
  clear: both;
}

.layout-center {
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 auto;
  max-width: 1165px;
}

@media (min-width: 777px) {
  .layout-center {
    padding-left: 12px;
    padding-right: 13px;
  }
  [dir="rtl"] .layout-center {
    padding-left: 13px;
    padding-right: 12px;
  }
}

.layout-center--shared-grid,
.layout-center.layout-3col {
  padding-left: 0;
  padding-right: 0;
}

.layout-swap {
  position: relative;
}

@media (min-width: 555px) {
  .layout-swap {
    padding-top: 48px;
  }
}

@media (min-width: 555px) {
  .layout-swap__top {
    position: absolute;
    top: 0;
    height: 48px;
    width: 100%;
  }
}

.box {
  margin-bottom: 1.5rem;
  border: 5px solid #cccccc;
  padding: 1em;
}

.box__title {
  margin: 0;
}

.box:focus, .box:hover, .box.is-focus, .box--is-focus {
  border-color: #000;
}

.box--highlight {
  border-color: #0072b9;
}

.clearfix:before,
.header:before,
.tabs:before {
  content: '';
  display: table;
}

.clearfix:after,
.header:after,
.tabs:after {
  content: '';
  display: table;
  clear: both;
}

.comment__section,
.comments {
  margin: 1.5rem 0;
}

.comment__title {
  margin: 0;
}

.comment__permalink {
  text-transform: uppercase;
  font-size: 75%;
}

.comment--preview,
.comment-preview {
  background-color: #fffadb;
}

.comment--nested,
.indented {
  margin-left: 32px;
}

[dir="rtl"] .comment--nested,
[dir="rtl"] .indented {
  margin-left: 0;
  margin-right: 32px;
}

.header__logo {
  float: left;
  padding: 0;
}

[dir="rtl"] .header__logo {
  float: right;
  margin: 0 0 0 10px;
}

.header__logo-image {
  vertical-align: bottom;
}

.header__name-and-slogan {
  float: left;
}

.header__site-name {
  margin: 0;
}

.header__site-link:link, .header__site-link:visited {
  color: #000;
  text-decoration: none;
}

.header__site-link:hover, .header__site-link:focus {
  text-decoration: underline;
}

.header__site-slogan {
  margin: 0;
}

.header__secondary-menu {
  float: right;
}

[dir="rtl"] .header__secondary-menu {
  float: left;
}

.header__region {
  clear: both;
}

.hidden,
html.js .js-hidden,
html.js .element-hidden,
html.js .js-hide {
  display: none;
}

.highlight-mark,
.new,
.update {
  color: #c00;
  background-color: transparent;
}

.inline-links,
.inline.links {
  padding: 0;
}

.inline-links__item,
.inline.links li {
  display: inline;
  list-style-type: none;
  padding: 0 1em 0 0;
}

[dir="rtl"] .inline-links__item,
[dir="rtl"] .inline.links li,
.inline.links [dir="rtl"] li {
  display: inline-block;
  padding: 0 0 0 1em;
}

.inline-sibling,
.field-label-inline .field-label,
span.field-label {
  display: inline;
  margin-right: 10px;
}

[dir="rtl"] .inline-sibling,
[dir="rtl"] .field-label-inline .field-label,
.field-label-inline [dir="rtl"] .field-label,
[dir="rtl"] span.field-label {
  margin-right: 0;
  margin-left: 10px;
}

.inline-sibling__child,
.inline-sibling *, .field-label-inline .field-label *, span.field-label * {
  display: inline;
}

.inline-sibling__adjacent,
.inline-sibling + *,
.inline-sibling + * > :first-child,
.inline-sibling + * > :first-child > :first-child, .field-label-inline .field-label + *, span.field-label + *, .field-label-inline .field-label + * > :first-child, span.field-label + * > :first-child, .field-label-inline .field-label + * > :first-child > :first-child, span.field-label + * > :first-child > :first-child {
  display: inline;
}

.field-label-inline .field-label,
.field-label-inline .field-items {
  float: none;
}

.messages,
.messages--status {
  margin: 1.5rem 0;
  position: relative;
  padding: 10px 10px 10px 44px;
  border: 1px solid #0072b9;
}

[dir="rtl"] .messages,
[dir="rtl"] .messages--status {
  padding: 10px 44px 10px 10px;
  background-position: 99% 8px;
}

.messages__icon {
  position: absolute;
  top: 50%;
  left: 10px;
  height: 24px;
  width: 24px;
  margin-top: -12px;
  line-height: 1;
}

[dir="rtl"] .messages__icon {
  left: auto;
  right: 0;
}

.messages__icon path {
  fill: #0072b9;
}

.messages__highlight,
.messages--error .error,
.messages.error .error {
  color: #000;
}

.messages__list {
  margin: 0;
}

.messages__item {
  list-style-image: none;
}

.messages--ok-color,
.messages,
.messages--status,
.ok,
.revision-current {
  background-color: #f6fcff;
  color: #0072b9;
}

.messages--warning-color,
.messages--warning,
.messages.warning,
.warning {
  background-color: #fffce6;
  color: #000;
}

.messages--error-color,
.messages--error,
.messages.error,
.error {
  background-color: #fff0f0;
  color: #c00;
}

.messages--warning,
.messages.warning {
  border-color: #fd0;
}

.messages--warning path, .messages.warning path {
  fill: #fd0;
}

.messages--error,
.messages.error {
  border-color: #c00;
}

.messages--error path, .messages.error path {
  fill: #c00;
}

@media print {
  .print-none,
  .toolbar,
  .action-links,
  .links,
  .book-navigation,
  .forum-topic-navigation,
  .feed-icons {
    display: none;
  }
}

.responsive-video,
.media-youtube-video,
.media-vimeo-preview-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
}

.responsive-video__embed,
.responsive-video iframe,
.media-youtube-video iframe,
.media-vimeo-preview-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.responsive-video--4-3 {
  padding-bottom: 75%;
}

.visually-hidden,
.element-invisible,
.element-focusable,
.breadcrumb__title,
.main-navigation .block-menu .block__title,
.main-navigation .block-menu-block .block__title {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  word-wrap: normal;
}

.visually-hidden--off,
.visually-hidden--focusable:active,
.visually-hidden--focusable:focus,
.element-focusable:active,
.element-focusable:focus {
  position: static !important;
  clip: auto;
  height: auto;
  width: auto;
  overflow: visible;
}

.watermark {
  font-family: Impact, "Arial Narrow", Helvetica, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  display: block;
  height: 0;
  overflow: visible;
  background-color: transparent;
  color: #eeeeee;
  font-size: 75px;
  line-height: 1;
  text-align: center;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
  word-wrap: break-word;
}

@media print {
  .breadcrumb {
    display: none;
  }
}

.breadcrumb__list {
  margin: 0;
  padding: 0;
}

.breadcrumb__item {
  display: inline;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

[dir="rtl"] .more-link,
[dir="rtl"] .more-help-link {
  text-align: left;
}

.more-link__help-icon,
.more-help-link a {
  padding: 1px 0 1px 20px;
  background-image: url(../sass/navigation/more-link/more-link__help-icon.svg);
  background-position: 0 50%;
  background-repeat: no-repeat;
}

[dir="rtl"] .more-link__help-icon,
[dir="rtl"] .more-help-link a,
.more-help-link [dir="rtl"] a {
  padding: 1px 20px 1px 0;
  background-position: 100% 50%;
}

.navbar,
.main-navigation .links,
.main-navigation .menu {
  margin: 0;
  padding: 0;
  text-align: left;
}

[dir="rtl"] .navbar,
[dir="rtl"] .main-navigation .links,
.main-navigation [dir="rtl"] .links,
[dir="rtl"]
.main-navigation .menu,
.main-navigation [dir="rtl"] .menu {
  text-align: right;
}

.navbar__item,
.navbar li, .main-navigation .links li,
.main-navigation .menu li {
  float: left;
  padding: 0 10px 0 0;
  list-style-type: none;
  list-style-image: none;
}

[dir="rtl"] .navbar__item, [dir="rtl"]
.navbar li, [dir="rtl"] .main-navigation .links li, .main-navigation [dir="rtl"] .links li, [dir="rtl"]
.main-navigation .menu li,
.main-navigation [dir="rtl"] .menu li {
  float: right;
  padding: 0 0 0 10px;
}

.pager {
  clear: both;
  padding: 0;
  text-align: center;
}

@media print {
  .pager {
    display: none;
  }
}

.pager__item,
.pager__current-item,
.pager-current,
.pager-item,
.pager-first,
.pager-previous,
.pager-next,
.pager-last,
.pager-ellipsis {
  display: inline;
  padding: 0 .5em;
  list-style-type: none;
  background-image: none;
}

.pager__current-item,
.pager-current {
  font-weight: bold;
}

.skip-link {
  display: block;
  padding: 2px 0 3px;
  text-align: center;
}

.skip-link:link, .skip-link:visited {
  background-color: #666666;
  color: #fff;
}

.skip-link__wrapper {
  margin: 0;
}

@media print {
  .skip-link__wrapper {
    display: none;
  }
}

.tabs {
  margin: 1.125rem 0 0;
  line-height: 1.875rem;
  border-bottom: 1px solid #cccccc \0/ie;
  background-image: linear-gradient(to top, #cccccc 1px, transparent 1px);
  padding: 0 2px;
  list-style: none;
  white-space: nowrap;
}

@media print {
  .tabs {
    display: none;
  }
}

.tabs__tab {
  float: left;
  margin: 0 3px;
  border: 1px solid #cccccc;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-color: transparent;
  border-bottom: 0 \0/ie;
  overflow: hidden;
  background: #dddddd;
}

[dir="rtl"] .tabs__tab {
  float: right;
}

.tabs__tab.is-active {
  border-bottom-color: #fff;
}

.tabs__tab-link {
  padding: 0 1.5rem;
  display: block;
  text-decoration: none;
  transition: color .3s, background .3s, border .3s;
  text-shadow: #fff 0 1px 0;
  color: #000;
  background: #dddddd;
  letter-spacing: 1px;
}

.tabs__tab-link:focus, .tabs__tab-link:hover {
  background: #eaeaea;
}

.tabs__tab-link:active, .tabs__tab-link.is-active, .tabs__tab-link--is-active {
  background: #fff;
  text-shadow: none;
}

.tabs--off,
.views-displays .secondary {
  margin: 0;
  border-bottom: 0;
  padding: 0;
  background-image: none;
}

.tabs--secondary {
  margin-top: 1.5rem;
  font-size: 0.875rem;
  background-image: none;
}

.tabs + .tabs--secondary {
  margin-top: 0;
  background-image: linear-gradient(to top, #cccccc 1px, transparent 1px);
}

.tabs--secondary .tabs__tab {
  margin: 0.5625rem 0.1875rem;
  border: 0;
  background: transparent;
}

[dir="rtl"] .tabs--secondary .tabs__tab {
  float: right;
}

.tabs--secondary .tabs__tab.is-active {
  border-bottom-color: transparent;
}

.tabs--secondary .tabs__tab-link {
  border: 1px solid #cccccc;
  border-radius: 24px;
  color: #666666;
  background: #f1f1f1;
  letter-spacing: normal;
}

.tabs--secondary .tabs__tab-link:focus, .tabs--secondary .tabs__tab-link:hover {
  color: #333333;
  background: #dddddd;
  border-color: #999999;
}

.tabs--secondary .tabs__tab-link:active, .tabs--secondary .tabs__tab-link.is-active, .tabs--secondary .tabs__tab-link--is-active {
  color: white;
  text-shadow: #333333 0 1px 0;
  background: #666666;
  border-color: black;
}

.autocomplete,
.form-autocomplete {
  background-image: url(../sass/forms/autocomplete/throbber-inactive.png);
  background-position: 100% center;
  background-repeat: no-repeat;
}

[dir="rtl"] .autocomplete,
[dir="rtl"] .form-autocomplete {
  background-position: 0% center;
}

.autocomplete__list-wrapper,
#autocomplete {
  border: 1px solid;
  overflow: hidden;
  position: absolute;
  z-index: 100;
}

.autocomplete__list,
#autocomplete ul {
  list-style: none;
  list-style-image: none;
  margin: 0;
  padding: 0;
}

.autocomplete__list-item,
#autocomplete li {
  background: #fff;
  color: #000;
  cursor: default;
  white-space: pre;
}

.autocomplete__list-item.is-selected, .autocomplete__list-item--is-selected,
#autocomplete li.is-selected,
#autocomplete .selected {
  background: #0072b9;
  color: #fff;
}

.autocomplete.is-throbbing, .autocomplete--is-throbbing,
.is-throbbing.form-autocomplete,
.form-autocomplete.throbbing {
  background-image: url(../sass/forms/autocomplete/throbber-active.gif);
}

.collapsible-fieldset,
.collapsible {
  position: relative;
}

.collapsible-fieldset__legend,
.collapsible .fieldset-legend {
  display: block;
  padding-left: 15px;
  background-image: url(../sass/forms/collapsible-fieldset/expanded.svg);
  background-position: 4px 50%;
  background-repeat: no-repeat;
}

[dir="rtl"] .collapsible-fieldset__legend,
[dir="rtl"] .collapsible .fieldset-legend,
.collapsible [dir="rtl"] .fieldset-legend {
  padding-left: 0;
  padding-right: 15px;
  background-position: right 4px top 50%;
}

.collapsible-fieldset__summary,
.collapsible .fieldset-legend .summary {
  color: #999999;
  font-size: .9em;
  margin-left: .5em;
}

.collapsible-fieldset.is-collapsed, .collapsible-fieldset--is-collapsed,
.is-collapsed.collapsible,
.collapsible.collapsed {
  border-bottom-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  height: 1em;
}

.collapsible-fieldset.is-collapsed .collapsible-fieldset__wrapper, .collapsible-fieldset--is-collapsed__wrapper, .is-collapsed.collapsible .collapsible-fieldset__wrapper,
.collapsible.collapsed .fieldset-wrapper {
  display: none;
}

.collapsible-fieldset.is-collapsed .collapsible-fieldset__legend, .collapsible-fieldset--is-collapsed__legend, .is-collapsed.collapsible .collapsible-fieldset__legend,
.collapsible.collapsed .fieldset-legend,
.collapsible-fieldset.is-collapsed .collapsible .fieldset-legend,
.collapsible .collapsible-fieldset.is-collapsed .fieldset-legend,
.is-collapsed.collapsible .fieldset-legend {
  background-image: url(../sass/forms/collapsible-fieldset/collapsed.svg);
  background-position: 4px 50%;
}

[dir="rtl"] .collapsible-fieldset.is-collapsed .collapsible-fieldset__legend, [dir="rtl"] .collapsible-fieldset--is-collapsed__legend, [dir="rtl"] .is-collapsed.collapsible .collapsible-fieldset__legend,
[dir="rtl"] .collapsible.collapsed .fieldset-legend,
.collapsible.collapsed [dir="rtl"] .fieldset-legend,
[dir="rtl"] .collapsible-fieldset.is-collapsed .collapsible .fieldset-legend,
.collapsible [dir="rtl"] .collapsible-fieldset.is-collapsed .fieldset-legend,
[dir="rtl"] .is-collapsed.collapsible .fieldset-legend {
  background-image: url(../sass/forms/collapsible-fieldset/collapsed-rtl.svg);
  background-position: right 4px top 50%;
}

.form-item {
  margin: 1.5rem 0;
}

.form-item__required,
.form-required {
  color: #c00;
}

.form-item__description,
.form-item .description {
  font-size: 0.875rem;
}

.form-item--inline div,
.form-item--inline label, .container-inline .form-item div, .container-inline .form-item label {
  display: inline;
}

.form-item--inline__exception,
.container-inline .fieldset-wrapper {
  display: block;
}

.form-item--tight,
.form-item--radio,
.form-type-radio,
.form-type-checkbox,
.password-parent,
.confirm-parent,
table .form-item {
  margin: 0;
}

.form-item--radio .form-item__label, .form-item--radio__label, .form-type-radio .form-item__label,
.form-type-checkbox .form-item__label,
label.option {
  display: inline;
  font-weight: normal;
}

.form-item--radio .form-item__description, .form-item--radio__description, .form-type-radio .form-item__description,
.form-type-checkbox .form-item__description,
.form-type-radio .description,
.form-type-checkbox .description,
.form-item--radio .form-item .description,
.form-item .form-item--radio .description,
.form-type-radio .form-item .description,
.form-item .form-type-radio .description,
.form-type-checkbox .form-item .description,
.form-item
.form-type-checkbox .description {
  margin-left: 1.4em;
}

.form-item.is-error .form-item__widget, .form-item--is-error__widget,
.form-item.is-error input,
.form-item.is-error textarea,
.form-item.is-error select,
.form-item--is-error input,
.form-item--is-error textarea,
.form-item--is-error select,
.form-item input.error,
.form-item textarea.error,
.form-item select.error {
  border: 1px solid #c00;
}

.form-table__sticky-header,
.sticky-header {
  position: fixed;
  visibility: hidden;
  margin-top: 0;
  background-color: #fff;
}

.form-table__sticky-header.is-sticky, .form-table__sticky-header--is-sticky,
.is-sticky.sticky-header {
  visibility: visible;
}

.form-table__header,
.form-table th,
form table th {
  border-bottom: 3px solid #cccccc;
  padding-right: 1em;
  text-align: left;
}

[dir="rtl"] .form-table__header,
[dir="rtl"] .form-table th,
.form-table [dir="rtl"] th,
[dir="rtl"] form table th,
form table [dir="rtl"] th {
  text-align: right;
  padding-left: 1em;
  padding-right: 0;
}

.form-table__body,
.form-table tbody,
form table tbody {
  border-top: 1px solid #cccccc;
}

.form-table__row,
.form-table tbody tr,
form table tbody tr {
  padding: .1em .6em;
  border-bottom: 1px solid #cccccc;
  background-color: #eeeeee;
}

.form-table__row:nth-child(even),
.form-table tbody tr:nth-child(even),
form table tbody tr:nth-child(even) {
  background-color: #fff;
}

.form-table__row.is-active, .form-table__row--is-active,
.form-table tbody tr.is-active,
form table tbody tr.is-active,
td.active {
  background-color: #dddddd;
}

.form-table__row.is-disabled, .form-table__row--is-disabled,
.form-table tbody tr.is-disabled,
form table tbody tr.is-disabled,
td.menu-disabled {
  background: #cccccc;
}

.form-table__row.is-selected, .form-table__row--is-selected,
.form-table tbody tr.is-selected,
form table tbody tr.is-selected,
tr.selected td {
  background: #fffdf0;
}

.form-table__list,
.form-table ul,
form table ul {
  margin: 0;
}

.form-table__narrow-column,
.form-table th.form-table__narrow-column,
form table th.form-table__narrow-column,
td .checkbox,
th .checkbox {
  width: -moz-min-content;
  width: -webkit-min-content;
  text-align: center;
}

.progress-bar,
.progress {
  font-weight: bold;
}

.progress-bar__bar,
.progress .bar {
  border-radius: 3px;
  margin: 0 .2em;
  border: 1px solid #666666;
  background-color: #cccccc;
}

.progress-bar__fill,
.progress .filled {
  height: 1.5em;
  width: 5px;
  background: #0072b9 url(../sass/forms/progress-bar/progress-bar.gif) repeat 0 0;
}

.progress-bar__percentage,
.progress .percentage {
  float: right;
}

[dir="rtl"] .progress-bar__percentage,
[dir="rtl"] .progress .percentage,
.progress [dir="rtl"] .percentage {
  float: left;
}

.progress-bar--inline,
.ajax-progress-bar {
  width: 16em;
  display: inline-block;
}

[dir="rtl"] .progress-bar--inline,
[dir="rtl"] .ajax-progress-bar {
  float: right;
}

.progress-throbber,
.ajax-progress {
  display: inline-block;
}

[dir="rtl"] .progress-throbber,
[dir="rtl"] .ajax-progress {
  float: right;
}

.progress-throbber__widget,
.ajax-progress .throbber {
  background: url(../sass/forms/progress-throbber/progress-throbber.gif) no-repeat 0 -18px transparent;
  float: left;
  height: 15px;
  margin: 2px;
  width: 15px;
}

[dir="rtl"] .progress-throbber__widget,
[dir="rtl"] .ajax-progress .throbber,
.ajax-progress [dir="rtl"] .throbber {
  float: right;
}

.progress-throbber__widget-in-tr,
tr .ajax-progress .throbber {
  margin: 0 2px;
}

.progress-throbber__message,
.ajax-progress .message {
  padding-left: 20px;
}

.resizable-textarea,
.resizable-textarea textarea {
  width: 100%;
  vertical-align: bottom;
}

.resizable-textarea__grippie,
.resizable-textarea .grippie {
  background: url(../sass/forms/resizable-textarea/grippie.png) no-repeat center 2px #eeeeee;
  border: 1px solid #cccccc;
  border-top-width: 0;
  cursor: s-resize;
  height: 9px;
  overflow: hidden;
}

.table-drag__wrapper,
body.drag {
  cursor: move;
}

.table-drag__item,
tr.drag {
  background-color: #fffadb;
}

.table-drag__item-previous,
tr.drag-previous {
  background-color: #fff7c2;
}

.table-drag__handle,
.tabledrag-handle {
  cursor: move;
  float: left;
  height: 1.7em;
  margin-left: -1em;
  overflow: hidden;
  text-decoration: none;
  font-size: 12px;
}

[dir="rtl"] .table-drag__handle,
[dir="rtl"] .tabledrag-handle {
  float: right;
  margin-right: -1em;
  margin-left: 0;
}

.table-drag__handle:focus, .table-drag__handle:hover,
.tabledrag-handle:focus,
.tabledrag-handle:hover {
  text-decoration: none;
}

.table-drag__handle-icon,
.tabledrag-handle .handle {
  box-sizing: content-box;
  background: url(../sass/forms/table-drag/handle-icon.png) no-repeat 6px 9px;
  height: 13px;
  margin: -.4em .5em;
  padding: .42em .5em;
  width: 13px;
}

.table-drag__handle.is-hover .table-drag__handle-icon, .table-drag__handle-icon--is-hover, .is-hover.tabledrag-handle .table-drag__handle-icon,
.tabledrag-handle-hover .handle,
.table-drag__handle.is-hover .tabledrag-handle .handle,
.tabledrag-handle .table-drag__handle.is-hover .handle,
.is-hover.tabledrag-handle .handle {
  background-position: 6px -11px;
}

.table-drag__toggle-weight-wrapper,
.tabledrag-toggle-weight-wrapper {
  text-align: right;
}

[dir="rtl"] .table-drag__toggle-weight-wrapper,
[dir="rtl"] .tabledrag-toggle-weight-wrapper {
  text-align: left;
}

.table-drag__toggle-weight,
.tabledrag-toggle-weight {
  font-size: .9em;
}

.table-drag__indentation,
.indentation {
  float: left;
  height: 1.7em;
  margin: -.4em .2em -.4em -.4em;
  padding: .42em 0 .42em .6em;
  width: 20px;
}

[dir="rtl"] .table-drag__indentation,
[dir="rtl"] .indentation {
  float: right;
  margin: -.4em -.4em -.4em .2em;
  padding: .42em .6em .42em 0;
}

.table-drag__tree-child,
.table-drag__tree-child-last,
.tree-child-last,
.table-drag__tree-child-horizontal,
.tree-child-horizontal,
.tree-child {
  background: url(../sass/forms/table-drag/tree-child.png) no-repeat 11px center;
}

[dir="rtl"] .table-drag__tree-child,
[dir="rtl"] .table-drag__tree-child-last,
[dir="rtl"] .tree-child-last,
[dir="rtl"] .table-drag__tree-child-horizontal,
[dir="rtl"] .tree-child-horizontal,
[dir="rtl"] .tree-child {
  background-position: -65px center;
}

.table-drag__tree-child-last,
.tree-child-last {
  background-image: url(../sass/forms/table-drag/tree-child-last.png);
}

.table-drag__tree-child-horizontal,
.tree-child-horizontal {
  background-position: -11px center;
}

/**
 * variables
 */
/**
* Place to drop all of your custom mixins
*/
/**
 * General styling partials
 */
body:after {
  content: 'default';
  display: none;
  position: fixed;
  top: 70px;
  right: 0;
  height: auto;
  padding: 10px 20px;
  color: black;
  background-color: red;
}

@media (min-width: 480px) {
  body:after {
    content: 'break-1';
    background-color: orange;
  }
}

@media (min-width: 768px) {
  body:after {
    content: 'break-2';
    background-color: yellow;
  }
}

@media (min-width: 960px) {
  body:after {
    content: 'break-3';
    background-color: green;
  }
}

@media (min-width: 1200px) {
  body:after {
    content: 'break-4';
    background-color: red;
  }
}

nav.tabs {
  display: block;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0;
  border: none;
  top: 200px;
  z-index: 50;
  padding: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  display: none;
  background-image: none;
  width: 150px;
}

@media (min-width: 768px) {
  nav.tabs {
    display: block;
  }
}

nav.tabs ul.tabs {
  margin: 0;
  padding: 0;
  border: none;
  background-image: none;
  width: 100%;
}

nav.tabs ul.tabs li {
  display: block;
  line-height: 1em;
  background: none;
  float: left;
  width: 100%;
  border: none;
}

nav.tabs ul.tabs a {
  font-size: 0.83333em;
  line-height: 2.26667em;
  background: none;
  font-weight: 300;
  color: white !important;
}

nav.tabs ul.tabs a:hover {
  text-decoration: underline;
}

* {
  box-sizing: border-box;
}

*:focus {
  outline: 0;
}

html {
  font-family: avenir, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 1.65em;
  font-weight: 300;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  min-height: 100%;
  color: #666666;
}

body {
  margin: 0;
  padding: 0;
  color: #666666;
}

.container {
  position: relative;
  display: block;
  padding: 0 10px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1020px;
}

.container:after {
  clear: both;
  content: '';
  display: table;
}

a {
  text-decoration: none;
}

.share-block {
  margin-bottom: 2em;
}

.share-block .label,
.share-block .share-block-wrapper {
  float: left;
}

.share-block .label {
  margin-right: 1em;
  color: #d42e41;
  font-size: 20px;
}

.share-block .share-link {
  display: inline-block;
  width: 30px;
  height: 20px;
  position: relative;
  text-align: center;
}

.share-block .share-link:before {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  content: "\e901";
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  font-size: 20px;
  color: #cecece;
  -webkit-transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  -ms-transition: color 0.3s ease;
  -o-transition: color 0.3s ease;
  transition: color 0.3s ease;
}

.share-block .share-link:hover:before {
  color: #d42e41;
}

.share-block .share-link.facebook-link:before {
  content: "\ea90";
}

.share-block .share-link.twitter-link:before {
  content: "\ea96";
}

.share-block .share-link.linkedin-link:before {
  content: "\eaca";
}

.share-block .share-link.google-plus-link:before {
  content: "\ea8b";
}

.share-block .share-link.mail-link:before {
  content: "\e905";
}

@media (min-width: 768px) {
  .share-block {
    margin-bottom: 4em;
  }
}

.back-to-overview,
.blog-overview-link {
  margin-bottom: 2em;
}

.back-to-overview .back-link,
.back-to-overview .blog-link,
.blog-overview-link .back-link,
.blog-overview-link .blog-link {
  position: relative;
  padding-left: 1.5em;
  text-decoration: underline;
  color: #cecece;
}

.back-to-overview .back-link:before,
.back-to-overview .blog-link:before,
.blog-overview-link .back-link:before,
.blog-overview-link .blog-link:before {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  content: "\e901";
  position: absolute;
  left: 0;
  top: 3px;
  font-size: 15px;
  color: #cecece;
  -webkit-transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  -ms-transition: color 0.3s ease;
  -o-transition: color 0.3s ease;
  transition: color 0.3s ease;
}

.back-to-overview .back-link:hover,
.back-to-overview .blog-link:hover,
.blog-overview-link .back-link:hover,
.blog-overview-link .blog-link:hover {
  text-decoration: none;
}

@media (min-width: 768px) {
  .back-to-overview,
  .blog-overview-link {
    margin-bottom: 0;
    text-align: right;
  }
}

.blog-header-wrap {
  float: left;
  width: 100%;
}

.blog-header-wrap .title {
  width: 50%;
  float: left;
}

.blog-header-wrap .blog-overview-link {
  width: 50%;
  float: right;
  text-align: right;
  line-height: 40px;
  margin-bottom: 0 !important;
}

p {
  margin-bottom: 1em;
  margin-top: 0;
}

p.title {
  font-size: 28px;
  line-height: 40px;
  color: #222222;
}

.ck-button {
  background-color: #dd5c6b;
  padding: 0.7em 1.5em;
  margin-top: 1.5em;
  display: inline-block;
  text-decoration: underline;
  margin-bottom: 0;
}

.ck-button:hover {
  text-decoration: none;
}

.has-bg {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover !important;
}

.read-more {
  padding: 1em;
  border: 1px solid white;
}

@media (min-width: 768px) {
  .text_in_3_columns {
    -webkit-column-count: 3;
    /* Chrome, Safari, Opera */
    -moz-column-count: 3;
    /* Firefox */
    column-count: 3;
    -webkit-column-gap: 20px;
    /* Chrome, Safari, Opera */
    -moz-column-gap: 20px;
    /* Firefox */
    column-gap: 20px;
  }
}

h1 {
  font-size: 32px;
  line-height: 45px;
  color: #222222;
  padding-bottom: 17px;
  margin-top: 0;
}

.page-node.not-front h1 {
  margin-top: 1em;
}

h2 {
  font-size: 25px;
  line-height: 30px;
  color: #222222;
}

h3, h4, h5, h6 {
  font-size: 18px;
  line-height: 20px;
  color: #222222;
}

strong {
  font-family: avenirblack7, Helvetica, sans-serif;
}

.ck-list {
  list-style-type: none;
  padding-left: 50px;
}

.ck-list li {
  position: relative;
  margin-bottom: 2em;
}

.ck-list li:before {
  content: "\e900";
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  left: -50px;
  font-size: 41px;
  top: -8px;
  color: #eeeeee;
}

.link {
  margin-top: 1em;
}

.link a {
  color: #666666;
  text-decoration: underline;
}

.link a:hover {
  text-decoration: none;
  color: black;
}

.pager {
  margin-top: 2em;
}

.pager li {
  color: #d42e41;
}

.pager li a {
  color: #666666;
}

.pager li a:hover {
  text-decoration: underline;
}

/**
 * view partials
 */
.basic-pages-overview {
  float: left;
  width: 100%;
}

.services-overview, .basic-pages-overview {
  background-color: #eeeeee;
  padding: 3em 0 2em;
}

.services-overview .container > .title, .basic-pages-overview .container > .title {
  text-align: center;
  position: relative;
  padding-bottom: 1em;
  margin-bottom: 2em;
}

.services-overview .container > .title:after, .basic-pages-overview .container > .title:after {
  content: '';
  height: 2px;
  width: 40px;
  background-color: #222222;
  position: absolute;
  bottom: 0;
  left: 0;
}

.services-overview .container > .title:after, .basic-pages-overview .container > .title:after {
  left: 0;
  right: 0;
  margin: 0 auto;
}

.services-overview .views-row, .basic-pages-overview .views-row {
  margin-bottom: 2em;
  float: left;
  width: 100%;
  background-color: white;
}

@media (min-width: 480px) {
  .services-overview .view-services, .basic-pages-overview .view-services {
    display: -webkit-box;
    /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box;
    /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox;
    /* TWEENER - IE 10 */
    display: -webkit-flex;
    /* NEW - Chrome */
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .services-overview .views-row, .basic-pages-overview .views-row {
    width: 48%;
    margin-right: 2%;
  }
  .services-overview .views-row.views-row-even, .basic-pages-overview .views-row.views-row-even {
    margin-right: 0;
    float: right;
  }
}

@media (min-width: 768px) {
  .services-overview .views-row, .basic-pages-overview .views-row {
    width: 32%;
    margin-right: 2%;
  }
  .services-overview .views-row.views-row-even, .basic-pages-overview .views-row.views-row-even {
    margin-right: 2%;
    float: left;
  }
  .services-overview .views-row.views-row-3, .services-overview .views-row.views-row-6, .services-overview .views-row.views-row-9, .basic-pages-overview .views-row.views-row-3, .basic-pages-overview .views-row.views-row-6, .basic-pages-overview .views-row.views-row-9 {
    margin-right: 0;
    float: right;
  }
}

.usp-overview > .title {
  text-align: center;
  position: relative;
  padding-bottom: 1em;
  margin-bottom: 2em;
}

.usp-overview > .title:after {
  content: '';
  height: 2px;
  width: 40px;
  background-color: #222222;
  position: absolute;
  bottom: 0;
  left: 0;
}

.usp-overview > .title:after {
  left: 0;
  right: 0;
  margin: 0 auto;
}

.usp-overview .views-row {
  text-align: center;
  margin-bottom: 2em;
}

@media (min-width: 480px) {
  .usp-overview .view-usp {
    display: -webkit-box;
    /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box;
    /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox;
    /* TWEENER - IE 10 */
    display: -webkit-flex;
    /* NEW - Chrome */
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .usp-overview .views-row {
    width: 33.33%;
    margin-bottom: 0;
  }
}

.overview-latest-blog,
.overview-linked-blog {
  float: left;
  width: 100%;
  padding: 4em 0;
}

.overview-latest-blog .view-blog,
.overview-linked-blog .view-blog {
  float: left;
}

.overview-latest-blog .blog-header-wrap > .title,
.overview-linked-blog .blog-header-wrap > .title {
  text-align: left;
  position: relative;
  padding-bottom: 1em;
  margin-bottom: 2em;
}

.overview-latest-blog .blog-header-wrap > .title:after,
.overview-linked-blog .blog-header-wrap > .title:after {
  content: '';
  height: 2px;
  width: 40px;
  background-color: #222222;
  position: absolute;
  bottom: 0;
  left: 0;
}

.overview-latest-blog .blog-header-wrap > .title:after,
.overview-linked-blog .blog-header-wrap > .title:after {
  left: 0;
}

@media (min-width: 768px) {
  .overview-latest-blog .blog-header-wrap > .title,
  .overview-linked-blog .blog-header-wrap > .title {
    text-align: left;
  }
  .overview-latest-blog .blog-header-wrap > .title:after,
  .overview-linked-blog .blog-header-wrap > .title:after {
    right: auto;
  }
  .overview-latest-blog .views-row,
  .overview-latest-blog .blog-articles,
  .overview-linked-blog .views-row,
  .overview-linked-blog .blog-articles {
    width: 45%;
    float: left;
  }
  .overview-latest-blog .views-row-odd,
  .overview-latest-blog .blog-articles.odd,
  .overview-linked-blog .views-row-odd,
  .overview-linked-blog .blog-articles.odd {
    margin-right: 5%;
  }
}

/**
 * Navigation styling partial
 */
ul.menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

.main-menu {
  float: left;
  width: 100%;
  display: none;
}

@media (min-width: 960px) {
  .main-menu {
    display: block;
    float: right;
    width: 60%;
    padding-top: 0.5em;
  }
  .main-menu .title {
    display: none;
  }
  .main-menu .menu {
    display: table;
    width: auto;
    table-layout: fixed;
    text-align: right;
    border-collapse: separate;
    float: right;
  }
  .main-menu .menu__item {
    float: left;
    line-height: 2em;
    display: table-cell;
    position: relative;
  }
  .main-menu .menu__item.last {
    margin-right: 0;
  }
  .main-menu .menu__item .menu__link {
    color: #666666;
  }
  .main-menu .menu__item.active, .main-menu .menu__item.active-trail, .main-menu .menu__itemis-active-trail {
    background-color: transparent;
    font-weight: 600;
  }
  .main-menu .menu__item.active a, .main-menu .menu__item.active-trail a, .main-menu .menu__itemis-active-trail a {
    color: #d42e41;
  }
  .main-menu .menu__item:hover {
    background-color: transparent;
  }
  .main-menu .menu__item:hover a {
    color: #d42e41;
  }
  .frontpage-header .main-menu .menu__item.active, .frontpage-header .main-menu .menu__item.active-trail, .frontpage-header .main-menu .menu__itemis-active-trail {
    background-color: #8b8b8b;
  }
  .frontpage-header .main-menu .menu__item:hover {
    background-color: #8b8b8b;
  }
  .frontpage-header .main-menu .menu__item .menu__link {
    color: white;
  }
  .main-menu .menu__item .menu__link {
    padding: 0 8px;
    display: block;
  }
}

@media (min-width: 960px) {
  .main-menu .menu__item {
    margin: 0 5px;
  }
}

.language-nav {
  float: left;
  width: 100%;
  background-color: #eeeeee;
  margin-right: -200em;
  margin-left: -200em;
  padding-right: 200em;
  padding-left: 200em;
  box-sizing: content-box;
  margin-top: 2em;
  margin-bottom: 2em;
  padding-top: 1.3em;
  padding-bottom: 1.3em;
}

@media (min-width: 960px) {
  .language-nav {
    display: none;
  }
}

.mobile-menu-button {
  float: left;
  cursor: pointer;
  text-transform: uppercase;
  padding-left: 2em;
  position: relative;
}

.mobile-menu-button:before {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  content: "\e901";
  position: absolute;
  left: 3px;
  top: 3px;
}

.mobile-menu {
  visibility: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999;
  top: 0;
  left: -100%;
  -webkit-transition: left 0.3s ease;
  transition: left 0.3s ease;
  background-color: #d42e41;
  padding: 1.5em;
}

.mobile-menu .title {
  color: white;
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 3px;
  position: relative;
  cursor: pointer;
}

.mobile-menu .title:after {
  content: "\e903";
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  position: absolute;
  right: 0px;
  top: 12px;
}

.mobile-menu .menu__link {
  color: white;
  line-height: 3em;
  display: block;
  padding-left: 1em;
  -webkit-transition: background-color 0.2s ease;
  -moz-transition: background-color 0.2s ease;
  -ms-transition: background-color 0.2s ease;
  -o-transition: background-color 0.2s ease;
  transition: background-color 0.2s ease;
}

.mobile-menu .menu__link:hover, .mobile-menu .menu__link.active {
  background-color: #dd5c6b;
}

.mobile-menu.open {
  visibility: visible;
  left: 0;
}

.desktop-language-switcher {
  display: none;
}

.desktop-language-switcher.front a {
  color: white;
}

.desktop-language-switcher.front a:hover, .desktop-language-switcher.front a.active {
  color: white;
}

@media (min-width: 960px) {
  .desktop-language-switcher {
    display: block;
  }
  .desktop-language-switcher .language-switcher {
    width: 100%;
    margin-bottom: 1em;
  }
}

.language-switcher {
  float: right;
  margin-bottom: 0;
  text-align: right;
}

.language-switcher > ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.language-switcher > ul > li {
  margin-left: 0.5em;
  list-style-type: none;
  list-style-image: none;
  display: inline-block;
}

.language-switcher > ul > li a {
  padding: 0.4em 0;
  text-decoration: none;
  color: #666666;
}

.language-switcher > ul > li a:hover, .language-switcher > ul > li a.active {
  font-weight: 700;
  color: black;
}

.footer-menu {
  width: 100%;
  text-align: center;
  margin: 2em 0 3em;
}

.footer-menu a {
  color: #666666;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.footer-menu a:hover {
  text-decoration: underline;
  color: black;
}

@media (min-width: 480px) {
  .footer-menu {
    width: 50%;
    float: left;
    text-align: left;
    padding-left: 2em;
    margin: 0 0 2em 0;
  }
}

@media (min-width: 768px) {
  .footer-menu {
    width: 25%;
    padding-left: 4em;
    margin: 0;
  }
}

.bottom-menu {
  text-align: center;
  color: #666666;
  margin-bottom: 1em;
}

.bottom-menu ul.menu {
  display: inline-block;
  margin: 0 auto;
}

.bottom-menu ul.menu li {
  float: left;
  padding-right: 1em;
}

.bottom-menu ul.menu li a {
  color: #666666;
}

#breadcrumb {
  margin: 1.5em 0;
}

#breadcrumb ul {
  display: inline-block;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

#breadcrumb ul li {
  float: left;
  padding-right: 30px;
  position: relative;
}

#breadcrumb ul li:after {
  position: absolute;
  right: 11px;
  content: '\203A';
}

#breadcrumb ul li a {
  color: #d42e41;
}

#breadcrumb ul li:last-child:after {
  content: none;
}

/**
 * entities styling partial
 */
.paragraph.red {
  background-color: #d42e41;
}

.paragraph.grey {
  background-color: #eeeeee;
}

.paragraph.text-color-black {
  color: black;
}

.paragraph.text-color-black p.title {
  color: black;
}

.paragraph.text-color-white {
  color: white;
}

.paragraph.text-color-white p.title {
  color: white;
}

.usp-overview-paragraph {
  padding: 4em 0;
  background-color: #eeeeee;
}

.images-block-paragraph {
  float: left;
  width: 100%;
}

.images-block-item {
  width: 50%;
  float: left;
  padding-bottom: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

@media (min-width: 768px) {
  .images-block-item {
    width: 25%;
    padding-bottom: 25%;
  }
}

.gallery-and-text_paragraph {
  padding: 1em 0 2em;
}

.gallery-and-text_paragraph .paragraph_gallery {
  width: 100%;
  margin-top: 30px;
  max-width: 574px;
}

.gallery-and-text_paragraph .paragraph_gallery .image_gallery {
  padding: 0;
  position: relative;
}

.gallery-and-text_paragraph .paragraph_gallery figcaption {
  text-align: center;
  font-style: italic;
}

.gallery-and-text_paragraph .paragraph_gallery .arrow-prev,
.gallery-and-text_paragraph .paragraph_gallery .arrow-next {
  width: 33px;
  height: 33px;
  background-color: #d42e41;
  text-align: center;
  font-size: 25px;
  color: white;
  position: absolute;
  bottom: 0;
  cursor: pointer;
  transition: background-color 0.3s ease;
  z-index: 2;
  top: 0;
  margin: auto 0;
  opacity: 0.5;
  filter: alpha(opacity=50);
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.gallery-and-text_paragraph .paragraph_gallery .arrow-prev:hover,
.gallery-and-text_paragraph .paragraph_gallery .arrow-next:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}

.gallery-and-text_paragraph .paragraph_gallery .arrow-prev:before,
.gallery-and-text_paragraph .paragraph_gallery .arrow-next:before {
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: block;
  bottom: 0;
}

.gallery-and-text_paragraph .paragraph_gallery .arrow-prev {
  left: 0;
}

.gallery-and-text_paragraph .paragraph_gallery .arrow-prev:before {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  content: "\e904";
  font-size: 32px;
}

.gallery-and-text_paragraph .paragraph_gallery .arrow-next {
  right: 0;
}

.gallery-and-text_paragraph .paragraph_gallery .arrow-next:before {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  content: "\e902";
  font-size: 32px;
}

@media (min-width: 768px) {
  .gallery-and-text_paragraph .paragraph_gallery {
    margin-top: 0;
  }
  .gallery-and-text_paragraph .paragraph_gallery .image_gallery {
    margin: 0;
  }
  .gallery-and-text_paragraph .paragraph_gallery .image_gallery .arrow-prev,
  .gallery-and-text_paragraph .paragraph_gallery .image_gallery .arrow-next {
    width: 66px;
    height: 66px;
  }
  .gallery-and-text_paragraph .paragraph_gallery .image_gallery .arrow-prev:before,
  .gallery-and-text_paragraph .paragraph_gallery .image_gallery .arrow-next:before {
    font-size: 40px;
    padding-top: 12px;
  }
  .gallery-and-text_paragraph .body-text_wrapper {
    margin-top: 20px;
  }
  .gallery-and-text_paragraph.left .paragraph_gallery {
    width: 53%;
    float: left;
  }
  .gallery-and-text_paragraph.left .body-text_wrapper {
    width: 40%;
    float: right;
  }
  .gallery-and-text_paragraph.right .paragraph_gallery {
    width: 53%;
    float: right;
  }
  .gallery-and-text_paragraph.right .body-text_wrapper {
    width: 40%;
    float: left;
  }
}

.centered-text-paragraph {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.centered-text-paragraph .text-wrapper {
  padding: 3em 0;
  text-align: center;
}

@media (min-width: 768px) {
  .centered-text-paragraph .text-wrapper {
    max-width: 60%;
    margin: 0 auto;
  }
}

.column-paragraph {
  padding: 4em 0;
}

.column-paragraph.red {
  color: white;
}

.column-paragraph.red .container > .title {
  color: white;
}

.column-paragraph.red .container > .title:after {
  background-color: white;
}

.column-paragraph .container > .title {
  text-align: center;
  position: relative;
  padding-bottom: 1em;
  margin-bottom: 2em;
}

.column-paragraph .container > .title:after {
  content: '';
  height: 2px;
  width: 40px;
  background-color: #222222;
  position: absolute;
  bottom: 0;
  left: 0;
}

.column-paragraph .container > .title:after {
  left: 0;
  right: 0;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .column-paragraph .container > .title {
    text-align: left;
  }
  .column-paragraph .container > .title:after {
    right: auto;
  }
}

.text-paragraph {
  padding: 3em 0 0;
}

.text-paragraph .text-wrapper {
  text-align: left;
}

@media (min-width: 768px) {
  .text-paragraph .text-wrapper {
    width: 72%;
    float: left;
    padding-right: 4em;
  }
}

/**
 * page styling partials
 * add structure
 */
.wrapper {
  overflow: hidden;
}

#top,
header,
#navigation,
#hero,
#main,
#content-above,
.content-bottom,
#content,
main,
.sidebar-left,
.sidebar-right,
#content-below,
footer,
#bottom {
  width: 100%;
  float: left;
}

header {
  padding-top: 2em;
  overflow: hidden;
}

header.frontpage-header {
  position: absolute;
  z-index: 2;
}

@media (min-width: 960px) {
  header {
    padding-top: 1em;
  }
}

.header__logo {
  text-align: center;
  width: 100%;
}

@media (min-width: 960px) {
  .header__logo {
    width: 40%;
    float: left;
  }
}

#navigation {
  background-color: #eeeeee;
  margin-top: 4em;
  display: none;
}

@media (min-width: 960px) {
  #navigation {
    display: block;
  }
}

footer {
  margin: 4em 0;
}

.footer-logo {
  width: 100%;
  text-align: center;
}

.footer-logo .footer-logo-image {
  width: 200px;
}

@media (min-width: 480px) {
  .footer-logo {
    width: 50%;
    float: left;
  }
}

@media (min-width: 768px) {
  .footer-logo {
    width: 25%;
  }
}

@media (min-width: 768px) {
  body.no-right-sidebar main,
  body.maintenance-page main {
    float: left;
    width: 100%;
  }
  body.one-sidebar.one-sidebar-left main {
    width: 60%;
    float: left;
  }
  body.one-sidebar.one-sidebar-left .sidebar-left {
    width: 40%;
    float: left;
  }
  body.one-sidebar.one-sidebar-right main {
    width: 70%;
    float: left;
    padding-right: 2em;
  }
  body.one-sidebar.one-sidebar-right .sidebar-right {
    width: 30%;
    float: left;
    padding-top: 3em;
  }
  body.two-sidebars main {
    width: 50%;
    float: left;
  }
  body.two-sidebars .sidebar-left {
    width: 30%;
    float: left;
  }
  body.two-sidebars .sidebar-right {
    width: 20%;
    float: right;
  }
}

@media (min-width: 960px) {
  body.one-sidebar.one-sidebar-right main {
    width: 72%;
    float: left;
    padding-right: 2em;
    padding-right: 4em;
  }
  body.one-sidebar.one-sidebar-right .sidebar-right {
    width: 28%;
    float: left;
    padding-top: 3em;
  }
}

/**
 * block styling partial
 */
.hero-image .has-bg {
  padding: 16em 0 9em;
}

.hero-image .banner-text-wrapper {
  display: block;
  padding: 0 10px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1020px;
  color: white;
  font-size: 1.8rem;
  line-height: 3rem;
}

.hero-image .banner-text-wrapper .read-more {
  font-size: 0.8rem;
  line-height: 3em;
  float: left;
  padding: 0 1em;
  color: white;
  letter-spacing: 4px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  margin-right: 1em;
  margin-bottom: 1em;
  clear: both;
}

.hero-image .banner-text-wrapper .read-more:hover {
  border-color: transparent;
  background-color: #d42e41;
}

@media (min-width: 768px) {
  .hero-image .has-bg {
    padding: 20em 0 11em;
  }
  .hero-image .banner-text-wrapper .read-more {
    padding: 0 2em;
    font-size: 1rem;
    clear: none !important;
  }
}

.cta-contact {
  float: left;
  width: 100%;
  text-align: center;
  background-color: #d42e41;
}

.cta-contact > a {
  padding: 4em 0;
  display: block;
  color: white;
}

.cta-contact .cta-wrap > .title {
  text-align: center;
  position: relative;
  padding-bottom: 1em;
  margin-bottom: 2em;
  color: white;
}

.cta-contact .cta-wrap > .title:after {
  content: '';
  height: 2px;
  width: 40px;
  background-color: #222222;
  position: absolute;
  bottom: 0;
  left: 0;
}

.cta-contact .cta-wrap > .title:after {
  left: 0;
  right: 0;
  margin: 0 auto;
  background-color: white;
}

@media (min-width: 768px) {
  .cta-contact .cta-wrap {
    max-width: 75%;
    margin: 0 auto;
  }
}

.contacts-footer {
  width: 100%;
  float: left;
}

.contacts-footer .contact-detail {
  width: 50%;
  float: left;
  padding-left: 1em;
}

@media (min-width: 768px) {
  .contacts-footer {
    width: 50%;
  }
}

.intro-block {
  margin-top: 2em;
  margin-bottom: 3em;
}

@media (min-width: 768px) {
  .intro-block {
    width: 75%;
  }
}

.blog-overview .view-filters {
  display: none;
}

.filters-overview {
  margin-bottom: 3em;
}

@media (min-width: 768px) {
  .filters-overview {
    margin-top: 2em;
  }
}

.filters-overview a {
  color: #d42e41;
  text-decoration: underline;
  padding-right: 7px;
}

.filters-overview a:hover {
  text-decoration: none;
}

.service-sidebar-overview,
.sidebar-menu-block,
.block-views-services-block_1-instance,
.linked-services,
.contact-page-services-overview {
  float: left;
  width: 100%;
  margin-bottom: 3em;
}

.service-sidebar-overview .item-list ul,
.service-sidebar-overview .menu-block-wrapper ul,
.service-sidebar-overview ul.list,
.sidebar-menu-block .item-list ul,
.sidebar-menu-block .menu-block-wrapper ul,
.sidebar-menu-block ul.list,
.block-views-services-block_1-instance .item-list ul,
.block-views-services-block_1-instance .menu-block-wrapper ul,
.block-views-services-block_1-instance ul.list,
.linked-services .item-list ul,
.linked-services .menu-block-wrapper ul,
.linked-services ul.list,
.contact-page-services-overview .item-list ul,
.contact-page-services-overview .menu-block-wrapper ul,
.contact-page-services-overview ul.list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.service-sidebar-overview .item-list ul li a,
.service-sidebar-overview .menu-block-wrapper ul li a,
.service-sidebar-overview ul.list li a,
.sidebar-menu-block .item-list ul li a,
.sidebar-menu-block .menu-block-wrapper ul li a,
.sidebar-menu-block ul.list li a,
.block-views-services-block_1-instance .item-list ul li a,
.block-views-services-block_1-instance .menu-block-wrapper ul li a,
.block-views-services-block_1-instance ul.list li a,
.linked-services .item-list ul li a,
.linked-services .menu-block-wrapper ul li a,
.linked-services ul.list li a,
.contact-page-services-overview .item-list ul li a,
.contact-page-services-overview .menu-block-wrapper ul li a,
.contact-page-services-overview ul.list li a {
  padding: 7px 0 7px 40px;
  color: #d42e41;
  display: block;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  position: relative;
}

.service-sidebar-overview .item-list ul li a:before,
.service-sidebar-overview .menu-block-wrapper ul li a:before,
.service-sidebar-overview ul.list li a:before,
.sidebar-menu-block .item-list ul li a:before,
.sidebar-menu-block .menu-block-wrapper ul li a:before,
.sidebar-menu-block ul.list li a:before,
.block-views-services-block_1-instance .item-list ul li a:before,
.block-views-services-block_1-instance .menu-block-wrapper ul li a:before,
.block-views-services-block_1-instance ul.list li a:before,
.linked-services .item-list ul li a:before,
.linked-services .menu-block-wrapper ul li a:before,
.linked-services ul.list li a:before,
.contact-page-services-overview .item-list ul li a:before,
.contact-page-services-overview .menu-block-wrapper ul li a:before,
.contact-page-services-overview ul.list li a:before {
  position: absolute;
  left: 18px;
  content: '\232A';
}

.service-sidebar-overview .item-list ul li a.active, .service-sidebar-overview .item-list ul li a:hover,
.service-sidebar-overview .menu-block-wrapper ul li a.active,
.service-sidebar-overview .menu-block-wrapper ul li a:hover,
.service-sidebar-overview ul.list li a.active,
.service-sidebar-overview ul.list li a:hover,
.sidebar-menu-block .item-list ul li a.active,
.sidebar-menu-block .item-list ul li a:hover,
.sidebar-menu-block .menu-block-wrapper ul li a.active,
.sidebar-menu-block .menu-block-wrapper ul li a:hover,
.sidebar-menu-block ul.list li a.active,
.sidebar-menu-block ul.list li a:hover,
.block-views-services-block_1-instance .item-list ul li a.active,
.block-views-services-block_1-instance .item-list ul li a:hover,
.block-views-services-block_1-instance .menu-block-wrapper ul li a.active,
.block-views-services-block_1-instance .menu-block-wrapper ul li a:hover,
.block-views-services-block_1-instance ul.list li a.active,
.block-views-services-block_1-instance ul.list li a:hover,
.linked-services .item-list ul li a.active,
.linked-services .item-list ul li a:hover,
.linked-services .menu-block-wrapper ul li a.active,
.linked-services .menu-block-wrapper ul li a:hover,
.linked-services ul.list li a.active,
.linked-services ul.list li a:hover,
.contact-page-services-overview .item-list ul li a.active,
.contact-page-services-overview .item-list ul li a:hover,
.contact-page-services-overview .menu-block-wrapper ul li a.active,
.contact-page-services-overview .menu-block-wrapper ul li a:hover,
.contact-page-services-overview ul.list li a.active,
.contact-page-services-overview ul.list li a:hover {
  background-color: #d42e41;
  color: white;
}

@media (min-width: 768px) {
  .service-sidebar-overview,
  .sidebar-menu-block,
  .block-views-services-block_1-instance,
  .linked-services,
  .contact-page-services-overview {
    margin-top: 2em;
    margin-bottom: 0;
  }
}

@media (min-width: 768px) {
  .linked-services {
    margin-bottom: 3em;
  }
}

.contact-page-services-overview {
  margin-top: 3em;
}

@media (min-width: 768px) {
  .contact-page-services-overview {
    width: 40%;
    float: right;
    margin-top: 0;
  }
}

.blog-service-pages {
  float: left;
  width: 100%;
  padding: 4em 0;
}

.blog-service-pages .container > .title {
  text-align: center;
  position: relative;
  padding-bottom: 1em;
  margin-bottom: 2em;
}

.blog-service-pages .container > .title:after {
  content: '';
  height: 2px;
  width: 40px;
  background-color: #222222;
  position: absolute;
  bottom: 0;
  left: 0;
}

.blog-service-pages .container > .title:after {
  left: 0;
  right: 0;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .blog-service-pages .container > .title {
    text-align: left;
  }
  .blog-service-pages .container > .title:after {
    right: auto;
  }
  .blog-service-pages .article-wrap {
    width: 45%;
    float: left;
  }
  .blog-service-pages .article-wrap.odd {
    margin-right: 5%;
  }
}

.contact-page-overview {
  margin-top: 2em;
}

.vacancies-overview {
  margin-bottom: 2em;
}

.vacancies-overview .view-vacancies {
  margin-bottom: 2em;
}

@media (min-width: 768px) {
  .vacancies-overview .view-vacancies {
    width: 70%;
    float: left;
    padding-right: 3.5em;
  }
  .vacancies-overview .sidebar-right.fake {
    width: 30%;
    float: left;
  }
}

.apply-block .title {
  font-size: 22px;
  line-height: 29px;
}

.apply-block .apply-button {
  color: white;
  text-decoration: none;
  transition: background-color 0.2s ease;
}

.apply-block .apply-button:hover {
  background-color: #d43245;
}

.apply-block.specific-apply-sidebar {
  margin-top: 2.6em;
}

/**
 * entities styling partial
 */
.node-service-teaser {
  width: 100%;
  height: 100%;
  display: block;
  padding: 1em 1em 1.5em;
  color: #666666;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.node-service-teaser p.title {
  color: #d42e41;
  font-size: 24px;
}

.node-service-teaser p.more-link {
  margin-top: 1em;
}

.front .services-overview .node-service-teaser:hover {
  background-color: #d42e41;
  color: rgba(255, 255, 255, 0.7);
}

.front .services-overview .node-service-teaser:hover p.title {
  color: white;
}

.front .services-overview .node-service-teaser:hover p.more-link {
  color: white;
}

.not-front .services-overview .node-service-teaser, .basic-pages-overview .node-service-teaser {
  border-top: 10px solid #d42e41;
  background-color: white;
}

.not-front .services-overview .node-service-teaser .more-link:hover, .basic-pages-overview .node-service-teaser .more-link:hover {
  color: #d42e41;
}

.node-service.view-mode-full {
  padding-bottom: 3em;
}

.node-homepage {
  padding: 4em 0;
}

.node-homepage h1 {
  position: relative;
}

.node-homepage h1:after {
  content: '';
  height: 2px;
  width: 40px;
  background-color: #222222;
  position: absolute;
  bottom: 0;
  left: 0;
}

@media (min-width: 768px) {
  .node-homepage .body-wrap {
    float: left;
    width: 100%;
  }
  .node-homepage .body-wrap .homepage-body-image,
  .node-homepage .body-wrap .homepage-body-text {
    width: 50%;
    float: left;
  }
  .node-homepage .body-wrap .homepage-body-text {
    padding-left: 2em;
  }
  .node-homepage .body-wrap-full {
    float: left;
    width: 100%;
  }
  .node-homepage .body-wrap-full .homepage-body-text {
    max-width: 75%;
  }
}

.node-usp {
  padding: 1em;
}

.node-usp .icon {
  margin-bottom: 2em;
}

.node-usp p.title {
  color: #d42e41;
  font-size: 24px;
  margin-bottom: 0.7em;
}

.node-usp .link {
  margin-top: 1em;
}

.node-usp .link a {
  color: #666666;
  text-decoration: underline;
}

.node-usp .link a:hover {
  text-decoration: none;
  color: black;
}

.linked-case {
  max-width: 320px;
  margin: auto;
  padding: 3em 0;
}

.linked-case .case {
  float: left;
  width: 100%;
  margin-top: 1em;
}

.linked-case .case > .title {
  text-align: center;
  position: relative;
  padding-bottom: 1em;
  margin-bottom: 1em;
}

.linked-case .case > .title:after {
  content: '';
  height: 2px;
  width: 40px;
  background-color: #222222;
  position: absolute;
  bottom: 0;
  left: 0;
}

.linked-case .case > .title a {
  color: black;
  -webkit-transition: color 0.2s ease;
  -moz-transition: color 0.2s ease;
  -ms-transition: color 0.2s ease;
  -o-transition: color 0.2s ease;
  transition: color 0.2s ease;
}

.linked-case .case > .title a:hover {
  color: #d42e41;
}

.linked-case .case > .title:after {
  left: 0;
  right: 0;
  margin: 0 auto;
}

.linked-case .cover-image {
  margin-bottom: 1em;
}

@media (min-width: 768px) {
  .linked-case {
    max-width: none;
  }
  .linked-case .case > .title {
    text-align: left;
  }
  .linked-case .case > .title:after {
    right: auto;
  }
  .linked-case .cover-image {
    width: 35%;
    float: left;
    margin: 0;
  }
  .linked-case .teaser-text {
    width: 65%;
    float: right;
    padding-left: 2em;
  }
}

.linked-team {
  background-color: #eeeeee;
  padding: 3em 0;
  width: 100%;
  float: left;
}

.linked-team .container > .title {
  text-align: center;
  position: relative;
  padding-bottom: 1em;
  margin-bottom: 2em;
}

.linked-team .container > .title:after {
  content: '';
  height: 2px;
  width: 40px;
  background-color: #222222;
  position: absolute;
  bottom: 0;
  left: 0;
}

.linked-team .container > .title:after {
  left: 0;
  right: 0;
  margin: 0 auto;
}

.linked-team .image {
  margin-bottom: 2em;
}

.linked-team .text {
  padding: 1.5em;
  background-color: #dddddd;
}

.linked-team .link {
  position: relative;
}

.linked-team .link:before {
  position: absolute;
  left: 10px;
  content: '\232A';
  color: #d42e41;
  top: 1px;
}

.linked-team .readmore {
  color: #d42e41;
  padding-left: 1.5em;
  position: relative;
}

.linked-team .readmore:hover {
  text-decoration: none;
  color: #d42e41;
}

@media (min-width: 768px) {
  .linked-team .image {
    width: 70%;
    padding-right: 2em;
    float: left;
  }
  .linked-team .text {
    width: 30%;
    float: left;
  }
}

.node-blog-teaser, .node-blog-full {
  margin-bottom: 3em;
}

.node-blog-teaser .tag-date-wrap, .node-blog-full .tag-date-wrap {
  margin-bottom: 10px;
}

.node-blog-teaser .date, .node-blog-full .date {
  color: #d42e41;
  font-style: italic;
  margin-right: 7px;
}

.node-blog-teaser .tags a, .node-blog-full .tags a {
  color: #d42e41;
  font-style: italic;
  margin-right: 5px;
  text-decoration: underline;
}

.node-blog-teaser .tags a:hover, .node-blog-full .tags a:hover {
  text-decoration: none;
}

.node-blog-teaser .title, .node-blog-full .title {
  font-size: 24px;
}

.node-blog-teaser .link, .node-blog-full .link {
  margin-top: 1em;
}

.node-blog-teaser .link a, .node-blog-full .link a {
  color: #666666;
  text-decoration: underline;
  padding: 0;
}

.node-blog-teaser .link a:hover, .node-blog-full .link a:hover {
  text-decoration: none;
  color: black;
}

.contact-detail .title {
  font-size: 18px;
  line-height: 1.4em;
  margin-bottom: 7px;
}

.contact-detail-full {
  float: left;
  width: 100%;
  margin-bottom: 2em;
}

.contact-detail-full .title {
  text-align: left;
  position: relative;
  padding-bottom: 1em;
  margin-bottom: 1em;
}

.contact-detail-full .title:after {
  content: '';
  height: 2px;
  width: 40px;
  background-color: #222222;
  position: absolute;
  bottom: 0;
  left: 0;
}

.contact-detail-full .sub_title {
  color: black;
  font-weight: 600;
  margin-bottom: 0.7em;
}

.contact-detail-full .contact__address {
  margin-bottom: 0.7em;
}

.contact-detail-full a {
  color: #666666;
}

.contact-detail-full .contact__email a {
  color: #d42e41;
}

.contact-detail-full .contact__email a:hover {
  text-decoration: underline;
}

.contact-detail-full .contact-detail--left,
.contact-detail-full .contact-detail--right {
  margin-bottom: 1em;
}

.contact-detail-full .openinghours p {
  margin-bottom: 0.7em;
}

@media (min-width: 480px) {
  .contact-detail-full .contact-detail--left,
  .contact-detail-full .contact-detail--right {
    width: 47%;
    float: left;
  }
  .contact-detail-full .contact-detail--left {
    margin-right: 3%;
  }
}

@media (min-width: 768px) {
  .contact-detail-full {
    width: 50%;
    float: left;
    margin-bottom: 3em;
  }
}

@media (min-width: 768px) {
  .no-sidebars .node-page.view-mode-full {
    max-width: 75%;
  }
}

.node-page.view-mode-full {
  padding: 2em 0 0;
}

.page-node-12 .node-page.view-mode-full,
.page-node-16 .node-page.view-mode-full {
  padding: 3em 0 0;
}

/**
 * Form styling partial
 */
.form-item {
  margin-bottom: 0.5em;
}

.newsletter {
  background-color: #d42e41;
  margin-bottom: 3em;
  padding: 2em 1em;
  color: white;
  float: left;
  width: 100%;
}

.newsletter .title {
  position: relative;
  padding-bottom: 1em;
  margin-bottom: 1em;
  color: white;
}

.newsletter .title:after {
  content: '';
  height: 2px;
  width: 40px;
  background-color: #222222;
  position: absolute;
  bottom: 0;
  left: 0;
}

.newsletter .title:after {
  left: 0;
  background-color: white;
}

.newsletter input[type="submit"] {
  height: 50px;
  line-height: 50px;
  padding: 0 30px;
  -webkit-appearance: none;
  background-color: #dd5c6b;
  border: none;
  color: white;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.newsletter input[type="submit"]:hover {
  background-color: #ac2333;
}

.newsletter input[type="email"] {
  height: 50px;
  line-height: 50px;
  padding-left: 10px;
  outline: none !important;
  border: none;
}

@media (min-width: 768px) {
  .newsletter input[type="submit"] {
    width: 100%;
  }
}

.contact-form {
  margin-bottom: 3em;
}

.contact-form .title {
  position: relative;
  padding-bottom: 1em;
  margin-bottom: 1em;
}

.contact-form .title:after {
  content: '';
  height: 2px;
  width: 40px;
  background-color: #222222;
  position: absolute;
  bottom: 0;
  left: 0;
}

.contact-form .title:after {
  left: 0;
  background-color: #666666;
}

.contact-form form input[type="text"],
.contact-form form input[type="tel"],
.contact-form form input[type="email"] {
  height: 50px;
  line-height: 50px;
  padding: 0 30px;
}

.contact-form form label {
  margin-bottom: 7px;
}

.contact-form form .form-item {
  margin: 9px 0;
}

.contact-form form textarea {
  padding: 10px 30px;
}

.contact-form form input[type="submit"] {
  height: 50px;
  line-height: 50px;
  padding: 0 30px;
  -webkit-appearance: none;
  background-color: #d42e41;
  border: none;
  color: white;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.contact-form form input[type="submit"]:hover {
  background-color: #dd5867;
}

@media (min-width: 768px) {
  .contact-form {
    width: 50%;
    float: left;
  }
}

input[type="text"],
input[type="tel"],
input[type="password"],
input[type="email"],
input[type="submit"],
textarea {
  outline: none !important;
  -webkit-appearance: none;
  border: 1px solid #b7b7b7;
}

textarea:focus, input:focus {
  outline: none !important;
}

*:focus {
  outline: none !important;
}
