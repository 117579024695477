/**
 * General styling partials
 */

//breakpoint help
// !important! 'display: none;' afterwards. there is a module dependencie (dms_adaptive_image)
body {

  &:after {
    content: 'default';
    //display: block;
    display: none;
    position: fixed;
    top: 70px;
    right: 0;
    height: auto;
    padding: 10px 20px;

    color: black;
    background-color: red;
  }
}
@media (min-width: $break-1) {
  body {
    &:after {
      content: 'break-1';
      background-color: orange;
    }
  }
}
@media (min-width: $break-2) {
  body {
    &:after {
      content: 'break-2';
      background-color: yellow;
    }
  }
}
@media (min-width: $break-3) {
  body {
    &:after {
      content: 'break-3';
      background-color: green;
    }
  }
}
@media (min-width: $break-4) {
  body {
    &:after {
      content: 'break-4';
      background-color: red;
    }
  }
}

//-------------------
//------ TABS -------
//-------------------

nav.tabs {
  display: block;
  background-color: rgba(0,0,0,0.5);
  position: fixed;
  left: 0;
  border: none;
  top: 200px;
  z-index: 50;
  padding: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  display: none;
  background-image: none;
  width: 150px;

  @media (min-width: $break-2) {
    display: block;
  }
}

nav.tabs ul.tabs {
  margin: 0;
  padding: 0;
  border: none;
  background-image: none;
  width: 100%;
}

nav.tabs ul.tabs li {
  display: block;
  line-height: 1em;
  background: none;
  float: left;
  width: 100%;
  border: none;
}

nav.tabs ul.tabs a {
  font-size: 0.83333em;
  line-height: 2.26667em;
  background: none;
  font-weight: 300;
  color: $white!important;
}

nav.tabs ul.tabs a:hover {
  text-decoration: underline;
}



* {
  box-sizing: border-box;
  //remove blue outline on focus
  &:focus {
    outline: 0;
  }
}

//html
html {
  font-family: $avenir;
  // Change the default font family in all browsers (opinionated).
  font-size: 16px;
  line-height: 1.65em;
  font-weight: 300;
  // Prevent adjustments of font size after orientation changes in IE and iOS.
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  // On short pages, we want any background gradients to fill the entire height
  // of the browser.
  min-height: 100%;
  color: $text-grey;
}


//body
body {
  // Output a horizontal grid to help with debugging typography. The
  // $typey-debug variable will toggle its output.
  @include typey-debug-grid();
  // Remove default margin and padding.
  margin: 0;
  padding: 0;
  color: $text-grey;
}



//container
.container {
  position: relative;
  display: block;
  padding: 0 10px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1020px;

  &:after {
    clear: both;
    content: '';
    display: table;
  }
}

//general link styling
a {
  text-decoration: none;
}


//share buttons

.share-block {
  margin-bottom: 2em;

  .label,
  .share-block-wrapper{
    float: left;
  }

  .label {
    margin-right: 1em;
    color: $red;
    font-size: 20px;
  }

  .share-link {
    display: inline-block;
    width: 30px;
    height: 20px;
    position: relative;
    text-align: center;

    &:before {
      @include icomoon();
      content: "\e901";
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      font-size: 20px;
      color: #cecece;
      @include transition(color 0.3s ease);
    }

    &:hover {
      &:before {
        color: $red;
      }
    }

    &.facebook-link {
      &:before {
        content: "\ea90";
      }
    }
    &.twitter-link{
      &:before {
        content: "\ea96";
      }

    }
    &.linkedin-link{
      &:before {
        content: "\eaca";
      }

    }
    &.google-plus-link{
      &:before {
        content: "\ea8b";
      }

    }
    &.mail-link{
      &:before {
        content: "\e905";
      }
    }
  }

  @media (min-width: $break-2) {
    margin-bottom: 4em;
  }
}

//back to overview
.back-to-overview,
.blog-overview-link {
  margin-bottom: 2em;


  .back-link,
  .blog-link {
    position: relative;
    padding-left: 1.5em;
    text-decoration: underline;
    color: #cecece;

    &:before {
      @include icomoon();
      content: "\e901";
      position: absolute;
      left: 0;
      top: 3px;
      font-size: 15px;
      color: #cecece;
      @include transition(color 0.3s ease);
    }

    &:hover {
      text-decoration: none;
    }
  }


  @media (min-width: $break-2) {
    margin-bottom: 0;

    text-align: right;
  }
}

.blog-header-wrap {
  float: left;
  width: 100%;

  .title {
    width: 50%;
    float: left;
  }

  .blog-overview-link {
    width: 50%;
    float: right;
    text-align: right;
    line-height:40px;
    margin-bottom: 0 !important;
  }
}

//paragraphs
p {
  margin-bottom: 1em;
  margin-top: 0;
}

p.title {
  font-size: 28px;
  line-height: 40px;
  color: $title-grey;
}

.ck-button {
  background-color: #dd5c6b;
  padding: 0.7em 1.5em;
  margin-top: 1.5em;
  display: inline-block;
  text-decoration: underline;
  margin-bottom: 0;

  &:hover {
    text-decoration: none;
  }

}

//background image
.has-bg {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover !important;
}

//readmore link

.read-more {
  padding: 1em;
  border: 1px solid $white;
}

//text in 3 columns

.text_in_3_columns {
  @media (min-width: $break-2) {
    -webkit-column-count: 3; /* Chrome, Safari, Opera */
    -moz-column-count: 3; /* Firefox */
    column-count: 3;
    -webkit-column-gap: 20px; /* Chrome, Safari, Opera */
    -moz-column-gap: 20px; /* Firefox */
    column-gap:  20px;
  }
}

h1 {
  font-size: 32px;
  line-height: 45px;
  color: $title-grey;
  padding-bottom: 17px;
  margin-top: 0;

  .page-node.not-front & {
    margin-top: 1em;
  }
}

h2 {
  font-size: 25px;
  line-height: 30px;
  color: $title-grey;
}

h3, h4, h5, h6 {
  font-size: 18px;
  line-height: 20px;
  color: $title-grey;
}

strong {
  font-family: $avenirbold;
}

.ck-list {
  list-style-type: none;
  padding-left: 50px;

  li {
    position: relative;
    margin-bottom: 2em;
    &:before {
      content: "\e900";
     @include icomoon();
      left: -50px;
      font-size: 41px;
      top: -8px;
      color: $grey;
    }
  }
}

.link {
  margin-top: 1em;

  a {
    color: $text-grey;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
      color: $black;
    }
  }
}

.pager {
  margin-top: 2em;
  li {
    color: $red;

    a {
      color: $text-grey;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
