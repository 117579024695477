/**
 * page styling partials
 * add structure
 */

// Main wrapper
.wrapper {
  overflow: hidden;
}

// Regions
#top,
header,
#navigation,
#hero,
#main,
#content-above,
.content-bottom,
#content,
main,
.sidebar-left,
.sidebar-right,
#content-below,
footer,
#bottom {
  width: 100%;
  float: left;
}


header {

  padding-top: 2em;
  overflow: hidden;

  &.frontpage-header {
    position: absolute;
    z-index: 2;
  }

  @media (min-width: $break-3) {
    padding-top: 1em;
  }
}

.header__logo {
  text-align: center;
  width: 100%;


  @media (min-width: $break-3) {
    width: 40%;
    float: left;
  }
}

#navigation {
  background-color: $grey;
  margin-top: 4em;
  display: none;

  @media (min-width: $break-3) {
    display: block;
  }
}

main {

}

footer {
  margin: 4em 0;
}

.footer-logo {
  width: 100%;
  text-align: center;
  .footer-logo-image {
    width: 200px;
  }


  @media (min-width: $break-1) {
    width: 50%;
    float: left;
  }

  @media (min-width: $break-2) {
    width: 25%;
  }
}

.content-bottom {
  //background-color: $grey;
  //padding: 3em 0;
  //margin-right: -200em;
  //margin-left: -200em;
  //padding-right: 200em;
  //padding-left: 200em;
  //box-sizing: content-box;
}



//*************************
//***** MEDIA QUERIES *****
//*************************


//End break-1

//-------------------
//-- BREAK 2 --------
//-------------------
@media (min-width: $break-2) {

  body.no-right-sidebar main,
  body.maintenance-page main {
      float: left;
      width: 100%;
  }

  body.one-sidebar.one-sidebar-left {
    main {
      width: 60%;
      float: left;
    }

    .sidebar-left {
      width: 40%;
      float: left;
    }
  }

  body.one-sidebar.one-sidebar-right {
    main {
      width: 70%;
      float: left;
      padding-right: 2em;
    }

    .sidebar-right {
      width: 30%;
      float: left;
      padding-top: 3em;
    }
  }

  body.two-sidebars {
    main {
      width: 50%;
      float: left;
    }

    .sidebar-left {
      width: 30%;
      float: left;
    }

    .sidebar-right {
      width: 20%;
      float: right;
    }
  }
}

// end break 2


//-------------------
//-- BREAK 3 --------
//-------------------
@media (min-width: $break-3) {


  body.one-sidebar.one-sidebar-right {
    main {
      width: 72%;
      float: left;
      padding-right: 2em;
      padding-right: 4em;
    }

    .sidebar-right {
      width: 28%;
      float: left;
      padding-top: 3em;
    }
  }
}
