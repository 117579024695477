// Drupal admin tables
//
// Complex forms that appear in html `<table>` elements.
//
// If your custom theme isn't used for Drupal's admin pages, you can safely
// delete this component to save file weight in the generated CSS.
//
// Markup: form-table.twig
//
// Style guide: forms.form-table

.form-table,
%form-table {
  &__sticky-header {
    position: fixed;
    visibility: hidden;
    margin-top: 0;
    background-color: color(body-bg);

    &.is-sticky,
    &--is-sticky {
      visibility: visible;
    }
  }

  &__header {
    border-bottom: 3px solid color(border);
    padding-right: 1em;
    text-align: left;

    @include rtl() {
      text-align: right;
      padding-left: 1em;
      padding-right: 0;
    }
  }

  &__body {
    border-top: 1px solid color(border);
  }

  &__row {
    padding: .1em .6em;
    border-bottom: 1px solid color(border);
    background-color: color(row-stripe);

    &:nth-child(even) {
      background-color: color(body-bg);
    }

    &.is-active,
    &--is-active {
      background-color: darken(color(row-stripe), 6.8%);
    }

    &.is-disabled,
    &--is-disabled {
      background: color(row-disabled);
    }

    &.is-selected,
    &--is-selected {
      background: color(table-select);
    }
  }

  &__list {
    margin: 0;
  }

  &__narrow-column {
    // sass-lint:disable no-vendor-prefixes, no-duplicate-properties
    width: -moz-min-content;
    width: -webkit-min-content;
    text-align: center;
  }

  // Automatically set styles on child HTML structure.
  th {
    @extend %form-table__header;

    // Override specificity. :-p
    &.form-table__narrow-column {
      @extend %form-table__narrow-column;
    }
  }

  tbody {
    @extend %form-table__body;

    tr {
      @extend %form-table__row;
    }
  }

  ul {
    @extend %form-table__list;
  }
}

//
// Drupal selectors.
//

.sticky-header {
  @extend %form-table__sticky-header;
}

form table {
  @extend %form-table;
}

td,
th {
  .checkbox {
    @extend %form-table__narrow-column;
  }
}

// Markup generated by theme_tablesort_indicator().
td.active {
  @extend %form-table__row--is-active;
}

td.menu-disabled {
  @extend %form-table__row--is-disabled;
}

// TableSelect behavior.
tr.selected td {
  @extend %form-table__row--is-selected;
}
