/**
 * variables
 */


//breakpoints
//Widths per breakpoint
$break-default: 320px;
$break-1: 480px;
$break-2: 768px;
$break-3: 960px;
$break-4: 1200px;


//colors

$grey: #eeeeee;
$white: white;
$black: black;
$red: #d42e41;
$text-grey: #666666;
$title-grey: #222222;


//custom font stacks
$avenir: avenir, Helvetica, sans-serif;
$avenirbold: avenirblack7, Helvetica, sans-serif;

//$proxima-nova:	  "proxima-nova", Arial, Helvetica, sans-serif;
//$fontawesome:     "Fontawesome";