/**
* Place to drop all of your custom mixins
*/

// reset css for list
@mixin reset-list() {
  list-style: none;
  margin: 0;
  padding: 0;
}

//opacity
@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}

//translateY
@mixin translateY($val) {
  -webkit-transform: translateY($val);
  -moz-transform: translateY($val);
  -ms-transform: translateY($val);
  -o-transform: translateY($val);
  transform: translateY($val);
}

//flex
@mixin flex() {
  display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box;         /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;      /* TWEENER - IE 10 */
  display: -webkit-flex;     /* NEW - Chrome */
  display: flex;
}

@mixin flex-wrap() {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}


@mixin striped() {
  position: relative;

  &:after {
    content: '';
    height: 2px;
    width: 40px;
    background-color: $title-grey;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

@mixin transition($args) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

@mixin icomoon() {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
}