/**
 * block styling partial
 */

//hero image
.hero-image {
  .has-bg {
    padding: 16em 0 9em;
  }

  .banner-text-wrapper {
    display: block;
    padding: 0 10px;
    margin-left: auto;
    margin-right: auto;
    max-width: 1020px;
    color: $white;
    font-size: 1.8rem;
    line-height: 3rem;


    .read-more {
      font-size: 0.8rem;
      line-height: 3em;
      float: left;
      padding: 0 1em;
      color: $white;
      letter-spacing: 4px;
      @include transition(all 0.3s ease);
      margin-right: 1em;
      margin-bottom: 1em;
      clear: both;

      &:hover {
        border-color: transparent;
        background-color: $red;
      }
    }
  }

  @media (min-width: $break-2) {
    .has-bg {
      padding: 20em 0 11em;
    }

    .banner-text-wrapper .read-more {
      padding: 0 2em;
      font-size: 1rem;
      clear: none !important;
    }
  }

}

//cta-contact

.cta-contact {
  float: left;
  width: 100%;
  text-align: center;
  background-color: $red;

  > a {
    padding: 4em 0;
    display: block;
    color: $white;
  }

  .cta-wrap > .title {
    text-align: center;
    @include striped();
    padding-bottom: 1em;
    margin-bottom: 2em;
    color: $white;

    &:after {
      left: 0;
      right: 0;
      margin: 0 auto;
      background-color: $white;
    }
  }

  @media (min-width: $break-2) {
    .cta-wrap {
      max-width: 75%;
      margin: 0 auto;
    }
  }
}

//contacts overview footer

.contacts-footer {
  width: 100%;
  float: left;

  .contact-detail {
    width: 50%;
    float: left;
    padding-left: 1em;
  }

  @media (min-width: $break-2) {
    width: 50%;
  }
}

//intro block

.intro-block {
  margin-top: 2em;
  margin-bottom: 3em;
  @media (min-width: $break-2) {
    width: 75%;
  }
}

//blog overview blocks

.blog-overview {
  .view-filters {
    display: none;
  }
}

.filters-overview {
  margin-bottom: 3em;

  @media (min-width: $break-2) {
    margin-top: 2em;
  }

  a {
    color: $red;
    text-decoration: underline;
    padding-right: 7px;

    &:hover {
      text-decoration: none;
    }

  }

}

//service sidebar oveview

.service-sidebar-overview,
.sidebar-menu-block,
.block-views-services-block_1-instance,
.linked-services,
.contact-page-services-overview {
  float: left;
  width: 100%;
  margin-bottom: 3em;

  .item-list ul,
  .menu-block-wrapper ul,
  ul.list {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {

      a {
        padding: 7px 0 7px 40px;
        color: $red;
        display: block;
        @include transition(all 0.3s ease);
        position: relative;

        &:before {
            position: absolute;
            left: 18px;
            content: '\232A';
        }

        &.active,
        &:hover {
          background-color: $red;
          color: $white;
        }
      }
    }
  }

  @media (min-width: $break-2) {
    margin-top: 2em;
    margin-bottom: 0;
  }
}


.linked-services {
  @media (min-width: $break-2) {
    margin-bottom: 3em;
  }
}

.contact-page-services-overview {
  margin-top: 3em;

  @media (min-width: $break-2) {
    width: 40%;
    float: right;
    margin-top: 0;
  }
}


//related blog articles on service pages

.blog-service-pages {
  float: left;
  width: 100%;
  padding: 4em 0;

  .container > .title {
    text-align: center;
    @include striped();
    padding-bottom: 1em;
    margin-bottom: 2em;

    &:after {
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }

  @media (min-width: $break-2) {

    .container > .title {
      text-align: left;

      &:after {
        right: auto;
      }
    }

    .article-wrap {
      width: 45%;
      float: left;
    }

    .article-wrap.odd {
      margin-right: 5%;
    }
  }
}

.contact-page-overview {
  margin-top: 2em;
}


//vacancies blog-overview

.vacancies-overview {
  margin-bottom: 2em;
  .view-vacancies {
      margin-bottom: 2em;
  }

  @media (min-width: $break-2) {
    .view-vacancies {
      width: 70%;
      float: left;
      padding-right: 3.5em;
    }

    .sidebar-right.fake {
      width: 30%;
      float: left;
    }
  }
}

//apply blocks
.apply-block {

  .title {
    font-size: 22px;
    line-height: 29px;
  }
  .apply-button {
    color: $white;
    text-decoration: none;
    transition: background-color 0.2s ease;

    &:hover {
      background-color: darken(#dd5c6b, 10%);
    }
  }

  &.specific-apply-sidebar {
    margin-top: 2.6em;
  }
}
