/**
 * entities styling partial
 */

.paragraph {

  &.red {
    background-color: $red;
  }

  &.grey {
    background-color: $grey;
  }

  &.text-color-black {
    color: $black;

    p.title {
      color: $black;
    }
  }

  &.text-color-white {
    color: $white;

    p.title {
      color: $white;
    }
  }
}

//usp
.usp-overview-paragraph {
  padding: 4em 0;
  background-color: $grey;
}

//block image

.images-block-paragraph {
  float: left;
  width: 100%;
}

.images-block-item {
  width: 50%;
  float: left;
  padding-bottom: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;


  @media (min-width: $break-2) {
    width: 25%;
    padding-bottom: 25%;
  }
}

//gallery + text paragraph

.gallery-and-text_paragraph {
  padding: 1em 0 2em;


  .paragraph_gallery {
    width: 100%;
    margin-top: 30px;
    max-width: 574px;

    .image_gallery {
      padding: 0;
      position: relative;
    }

    figcaption {
      text-align: center;
      font-style: italic;
    }

    .arrow-prev,
    .arrow-next {
      width: 33px;
      height: 33px;
      background-color: $red;
      text-align: center;
      font-size: 25px;
      color: $white;
      position: absolute;
      bottom: 0;
      cursor: pointer;
      transition: background-color 0.3s ease;
      z-index: 2;
      top: 0;
      margin: auto 0;
      @include opacity(0.5);
      @include transition(all 0.4s ease);

      &:hover {
        @include opacity(1);
      }


      &:before {
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        display: block;
        bottom: 0;
      }
    }

    .arrow-prev {
      left: 0;

      &:before {
        @include icomoon();
        content: "\e904";
        font-size: 32px;

      }
    }

    .arrow-next {
      right: 0;

      &:before {
        @include icomoon();
        content: "\e902";
        font-size: 32px;
      }
    }
  }

  @media (min-width: $break-2) {

    .paragraph_gallery{
      margin-top: 0;

      .image_gallery {
        margin: 0;


        .arrow-prev,
        .arrow-next {
          width: 66px;
          height: 66px;

          &:before {
            font-size: 40px;
            padding-top: 12px;
          }
        }
      }
    }

    .body-text_wrapper {
      margin-top: 20px;
    }


    &.left {
      .paragraph_gallery {
        width: 53%;
        float: left;
      }
      .body-text_wrapper {
        width: 40%;
        float: right;
      }
    }

    &.right {
      .paragraph_gallery {
        width: 53%;
        float: right;
      }
      .body-text_wrapper {
        width: 40%;
        float: left;
      }
    }
  }
}


//centered text

.centered-text-paragraph {

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  .text-wrapper {
    padding: 3em 0;
    text-align: center;

  }


  @media (min-width: $break-2) {
    .text-wrapper {
      max-width: 60%;
      margin: 0 auto;
    }
  }
}


//column paragraph

.column-paragraph {
  padding: 4em 0;

  &.red {
    color: $white;

    .container > .title {
      color: $white;

      &:after {
        background-color: $white;
      }
    }
  }

  .container > .title {
    text-align: center;
    @include striped();
    padding-bottom: 1em;
    margin-bottom: 2em;

    &:after {
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }

  @media (min-width: $break-2) {
    .container > .title {
      text-align: left;

      &:after {
        right: auto;
      }
    }
  }
}


//text paragraph

.text-paragraph {

  padding: 3em 0 0;


  .text-wrapper {
    text-align: left;
  }


  @media (min-width: $break-2) {
    .text-wrapper {
      width: 72%;
      float: left;
      padding-right: 4em;
    }
  }
}
