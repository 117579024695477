/**
 * entities styling partial
 */

//service

.node-service-teaser {
  width: 100%;
  height: 100%;
  display: block;
  padding: 1em 1em 1.5em;
  color: $text-grey;
  @include transition(all .3s ease);
  

  p.title {
    color: $red;
    font-size: 24px;
  }
  p.more-link {
    margin-top: 1em;
  }
}

.front .services-overview .node-service-teaser {
  &:hover {
    background-color: $red;
    color: rgba(255, 255, 255, 0.7);

    p.title {
      color: $white;
    }
    p.more-link {
      color: $white;
    }
  }
}

.not-front .services-overview, .basic-pages-overview {
  .node-service-teaser {
    border-top: 10px solid $red;
    background-color: $white;

    .more-link:hover {
      color: $red;
    }
  }
} 

.node-service.view-mode-full {
      padding-bottom: 3em;
}

//homepage

.node-homepage {
  padding: 4em 0;

  h1 {
    @include striped();
  }

  @media (min-width: $break-2) {
    .body-wrap {
      float: left;
      width: 100%;

      .homepage-body-image,
      .homepage-body-text {
        width: 50%;
        float: left;
      }

      .homepage-body-text {
        padding-left: 2em;
      }
    }

    .body-wrap-full {
      float: left;
      width: 100%;

      .homepage-body-text {
        max-width: 75%;
      }
    }
  }
}

//usp

.node-usp {
  padding: 1em;

  .icon {
    margin-bottom: 2em;
  }

  p.title {
    color: $red;
    font-size: 24px;
    margin-bottom: 0.7em;
  }

  .link {
    margin-top: 1em;

    a {
      color: $text-grey;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
        color: $black;
      }
    }
  }
}


//cases
.linked-case {
  max-width: 320px;
  margin: auto;
  padding: 3em 0;

  .case {
    float: left;
    width: 100%;
    margin-top: 1em;
  }

  .case > .title {
    text-align: center;
    @include striped();
    padding-bottom: 1em;
    margin-bottom: 1em;

    a {
      color: $black;
      @include transition(color 0.2s ease);

      &:hover {
        color: $red;
      }
    }

    &:after {
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }

  .cover-image {
    margin-bottom: 1em;
  }

  @media (min-width: $break-2) {
    max-width: none;


    .case > .title {
      text-align: left;

      &:after {
        right: auto;
      }
    }


    .cover-image {
      width: 35%;
      float: left;
      margin: 0;
    }

    .teaser-text {
      width: 65%;
      float: right;
      padding-left: 2em;
    }
  }
}

//team

.linked-team {
  background-color: $grey;
  padding: 3em 0;
  width: 100%;
  float: left;

  .container > .title {
    text-align: center;
    @include striped();
    padding-bottom: 1em;
    margin-bottom: 2em;

    &:after {
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }

  .image {
    margin-bottom: 2em;
  }

  .text {
    padding: 1.5em;
    background-color: #dddddd;
  }

  .link {
    position: relative;

    &:before {
      position: absolute;
      left: 10px;
      content: '\232A';
      color: $red;
      top: 1px;
    }

  }

  .readmore {
    color: $red;
    padding-left: 1.5em;
    position: relative;
    //text-decoration: underline;



    &:hover {
      text-decoration: none;
      color: $red;
    }
  }

  @media (min-width: $break-2) {
    .image {
      width: 70%;
      padding-right: 2em;
      float: left;
    }

    .text {
      width: 30%;
      float: left;
    }
  }
}


//blog

.node-blog-teaser, .node-blog-full {

  margin-bottom: 3em;
  
  .tag-date-wrap {
    margin-bottom: 10px;
  }

  .date {
    color: $red;
    font-style: italic;
    margin-right: 7px;
  }

  .tags {
    a {
      color: $red;
      font-style: italic;
      margin-right: 5px;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .title {
    font-size: 24px;
  }

  .link {
    margin-top: 1em;

    a {
      color: $text-grey;
      text-decoration: underline;
      padding: 0;

      &:hover {
        text-decoration: none;
        color: $black;
      }
    }
  }
}


//contact footer

.contact-detail {

  .title {
    font-size: 18px;
    line-height: 1.4em;
    margin-bottom: 7px;
  }
}

.contact-detail-full {
  float: left;
  width: 100%;
  margin-bottom: 2em;
  .title {
    text-align: left;
    @include striped();
    padding-bottom: 1em;
    margin-bottom: 1em;
  }

  .sub_title {
    color: $black;
    font-weight: 600;
    margin-bottom: 0.7em
  }

  .contact__address {
    margin-bottom: 0.7em;
  }

  a {
    color: $text-grey;
  }

  .contact__email a {
    color: $red;

    &:hover {
      text-decoration: underline;
    }
  }

  .contact-detail--left,
  .contact-detail--right {
    margin-bottom: 1em;
  }

  .openinghours {
    p {
      margin-bottom: 0.7em;
    }
  }

  @media (min-width: $break-1) {
    .contact-detail--left,
    .contact-detail--right {
      width: 47%;
      float: left;
    }

    .contact-detail--left {
      margin-right: 3%;
    }
  }

  @media (min-width: $break-2) {
      width: 50%;
      float: left;
    margin-bottom: 3em;

  }
}




.no-sidebars {
  .node-page {
    @media (min-width: $break-2) {

      &.view-mode-full {
        max-width: 75%;
      }
    }
  }
}


.node-page {

  &.view-mode-full {
    padding: 2em 0 0;
  }

  .page-node-12 &,
  .page-node-16 & {

    &.view-mode-full {
      padding: 3em 0 0;
    }
  }

}

