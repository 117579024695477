/**
 * view partials
 */

.basic-pages-overview {
  float: left;
  width: 100%;
}

.services-overview, .basic-pages-overview {

  background-color: $grey;
  padding: 3em 0 2em;

  .container > .title {
    text-align: center;
    @include striped();
    padding-bottom: 1em;
    margin-bottom: 2em;

    &:after {
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }

  .views-row {
    margin-bottom: 2em;
    float: left;
    width: 100%;
    background-color: $white;
  }

  @media (min-width: $break-1) {
    .view-services {
      @include flex();
      @include flex-wrap();
    }

    .views-row {
      width: 48%;
      margin-right: 2%;

      &.views-row-even {
        margin-right: 0;
        float: right;
      }

    }
  }

  @media (min-width: $break-2) {
    .view-services {

    }

    .views-row {
      width: 32%;
      margin-right: 2%;

      &.views-row-even {
        margin-right: 2%;
        float: left;
      }

      &.views-row-3,
      &.views-row-6,
      &.views-row-9 {
        margin-right: 0;
        float: right;
      }
    }
  }
}


//usp view

.usp-overview {

  > .title {
    text-align: center;
    @include striped();
    padding-bottom: 1em;
    margin-bottom: 2em;

    &:after {
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }

  .views-row {
    text-align: center;
    margin-bottom: 2em;
  }

  @media (min-width: $break-1) {

    .view-usp {
      @include flex();
      @include flex-wrap();
    }

    .views-row {
      width: 33.33%;
      margin-bottom: 0;
    }
  }
}

//blog

.overview-latest-blog,
.overview-linked-blog{
  float: left;
  width: 100%;
  padding: 4em 0;

  .view-blog {
    float: left;
  }

  .blog-header-wrap > .title {
    text-align: left;
    @include striped();
    padding-bottom: 1em;
    margin-bottom: 2em;

    &:after {
      left: 0;
    }
  }

  @media (min-width: $break-2) {

    .blog-header-wrap  > .title {
      text-align: left;

      &:after {
        right: auto;
      }
    }

    .views-row,
    .blog-articles {
      width: 45%;
      float: left;
    }

    .views-row-odd,
    .blog-articles.odd{
      margin-right: 5%;
    }
  }
}