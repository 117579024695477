/**
 * Form styling partial
 */

.form-item {
  margin-bottom: 0.5em;
}

.newsletter {
  background-color: $red;;
  margin-bottom: 3em;
  padding: 2em 1em;
  color: $white;
  float: left;
  width: 100%;

  .title {
    @include striped();
    padding-bottom: 1em;
    margin-bottom: 1em;
    color: $white;

    &:after {
      left: 0;
      background-color: $white;
    }
  }

  input[type="submit"]{
    //width: 100%;
    height: 50px;
    line-height: 50px;
    padding: 0 30px;
    -webkit-appearance:none;
    background-color: #dd5c6b;
    border: none;
    color: $white;
    @include transition(all 0.2s ease);

    &:hover {
      background-color: darken($red, 10%);
    }
  }

  input[type="email"] {
    height: 50px;
    line-height: 50px;
    padding-left: 10px;
    outline: none!important;
    border: none;
  }


  @media (min-width: $break-2) {
    input[type="submit"]{
      width: 100%;
    }
  }
}

.contact-form {

  margin-bottom: 3em;

  .title {
    @include striped();
    padding-bottom: 1em;
    margin-bottom: 1em;

    &:after {
      left: 0;
      background-color: $text-grey;
    }
  }

  form {
    input[type="text"],
    input[type="tel"],
    input[type="email"] {
      height: 50px;
      line-height: 50px;
      padding: 0 30px;
    }

    label {
      margin-bottom: 7px;
    }

    .form-item {
      margin: 9px 0;
    }

    textarea {
      padding: 10px 30px;
    }

    input[type="submit"]{
      height: 50px;
      line-height: 50px;
      padding: 0 30px;
      -webkit-appearance:none;
      background-color: $red;
      border: none;
      color: $white;
      @include transition(all 0.2s ease);

      &:hover {
        background-color: lighten($red, 10%);
      }
    }
  }

  @media (min-width: $break-2) {
    width: 50%;
    float: left;
  }
}


input[type="text"],
input[type="tel"],
input[type="password"],
input[type="email"],
input[type="submit"],
textarea {
  outline: none!important;
  -webkit-appearance: none; //Prevent rounded corners and shadow on IOS
  border: 1px solid #b7b7b7;
}

textarea:focus, input:focus{
  outline: none!important;
}

*:focus {
  outline: none!important;
}